
//引入共用地址
import {redirectToLogin} from './config.js';
import axios from 'axios';
import { Message } from 'element-ui';
import qs from 'qs';
import { getParam } from "../utils/utils";

let reqIndex = 0;
let trackID;
const transferUrl = (url) => {
  const base = getParam('base') ||  location.origin;
  let tmpUrl = url;
  const rewriteConfig = JSON.parse(localStorage.getItem('rewriteConfig') || '[]');
  if (rewriteConfig.length) {
    rewriteConfig.forEach(({ key, value }) => {
      tmpUrl = tmpUrl.startsWith(key) ? tmpUrl.replace(key, value) : tmpUrl;
    })
  }
  return new URL(tmpUrl, base);
};
const core = (url, method, data, header, dataType, responseType) => {
  header['Powder-ReqIndex'] = reqIndex++;
  if (trackID) {
    header['Powder-TrackID'] = trackID;
  }
  const xdebug = getParam('xdebug');
  if (xdebug) {
    url = appendQuery(url, 'XDEBUG_SESSION_START', xdebug);
  }
  if (getParam('debug')) {
    url = appendQuery(url, 'i', reqIndex-1);
    console.log('data:', data.data ? data.data : data);
  }
  //返回一个promise对象
  return axios({
    method: method,
    url: transferUrl(url),
    data: data,
    headers: header,
    dataType: dataType,
    responseType: responseType,
  });
};

// api/staffapi 公用
const apiCommon = async (param, tokenName) => {
  let url = param.url;
  let header = {'content-type': "application/json", ...param.header};
  let obj = {
    "appVersion": "1.0.0",
    "clientTime": 0,
    "system": "h5",
  };
  let token = window.localStorage.getItem(tokenName);
  if (token) {
    Object.assign(obj, {token: token})
  }
  let data = Object.assign(obj, {data: param.data});

  return core(url, "POST", data, header).then((res) => {
    if (res.data.code !== 1) {
      Message.error(res.data.message);
      return Promise.reject(res);
    }
    return res;
  });
};

//封装请求
export const dajxHttp = {
  // for test only
  cms: async (param) => {
    let header = {'content-type': "application/json"};
    // 跨域请求时，需要手动配置 PHPSESSID
    let phpsid = getParam("cmsphpsid");
    let url = '/cmsapi/index.php?s=/token';
    if (phpsid) {
      url = appendQuery(url, 'phpsid', phpsid);
    }
    let info = await core(url, "POST", {}, header);
    param.data.__token__ = info.data;
    header = {...header, ...param.header};
    url = param.url;
    if (phpsid) {
      url = appendQuery(url, 'phpsid', phpsid);
    }
    return core(url, "POST", param.data, header);
  },

  // for test only
  postScms: async (param) => {
    let header = {'content-type': "application/json", "token": window.localStorage.getItem('stoken')};
    return await core(param.url, "POST", param.data, header);
  },

  // for test only
  postRaw: (param) => {
    let url = param.url;
    let header = {'content-type': "application/json", ...param.header};
    return core(param.url, "POST", param.data, header);
  },

  postJsonAsFormUrl: (url, data) => {
    data = qs.stringify(data);
    return dajxHttp.postRaw({
        url,
        data,
        header: {
            "content-type": "application/x-www-form-urlencoded",
        }
    })
  },

  // for test only
  postStaff: (param) => {
    return apiCommon(param, 'tokenStaff').catch((res) => {
      // 跳转登录
      if (res.data.code === 100003) {
        Message.error('员工未登录');
      }
      return Promise.reject(res);
    });
  },

  post: (param) => {
    return apiCommon(param, 'token').then(res => (Promise.resolve(res))).catch((res) => {
      // 跳转登录
      if (redirectToLogin && res.data.code === 100003) {
        window.localStorage.clear();
      }
      return Promise.reject(res);
    });
  },
    get: (param) => { // TODO: 提取
        let obj = {
            "appVersion": "1.0.0",
            "clientTime": 0,
            "system": "mp-weixin",
        };
        token = window.localStorage.getItem('token');
        if (token) {
            Object.assign(obj, {token: token})
        }
        let objs = Object.assign(obj, {data: param.data});
        let method = 'GET',
            url = param.url,
            data = param.data || {},
            header = {'content-type': "application/x-www-form-urlencoded"},
            dataType = param.dataType,
            responseType = param.responseType;
        //返回一个promise对象
        return new Promise((resolve, reject) => {
            axios({
                method: method,
                url: url,
                data: data,
                header: header,
                dataType: dataType,
                responseType: responseType,
            }).then((res) => {
                resolve(res);
                if (res.data.code.data == 9999) {
                    Message.error('服务器异常');
                    //进入主页
                    return;
                }
            }).catch((e) => reject(e));
        })
    },
    put: (param) => { // TODO: 提取
        let obj = {
            "appVersion": "1.0.0",
            "clientTime": 0,
            "system": "mp-weixin",
            // "token":
        };
        token = window.localStorage.getItem('token');
        if (token) {
            Object.assign(obj, {token: token})
        }
        let objs = Object.assign(obj, {data: param.data});
        let method = 'PUT',
            url = param.url,
            data = param.data || {},
            header = {'content-type': "application/json"},
            dataType = param.dataType,
            responseType = param.responseType;
        //返回一个promise对象
        return new Promise((resolve, reject) => {
            axios({
                method: method,
                url: url,
                data: data,
                header: header,
                dataType: dataType,
                responseType: responseType,
                success: function (res) {
                    //接口返回结果，可根据前后端定义code来进行处理业务逻辑
                    resolve(res);
                    if (res.data.code == 9999) {
                        Message.error('服务器异常');
                        //进入主页
                        return
                    }
                    // if(res.data.code == 10000){
                    //   console.log(res)
                    // }
                },
                fail: function (err) {
                    return reject(err)
                }
            })
        })
    }
};

//微信支付接口 // TODO: 无用？
const wxPayments = (params) => {
    var timeStamp = params.timeStamp,
        nonceStr = params.nonceStr,
        prepay_id = params.prepay_id,
        paySign = params.paySign;
    return new Promise((resolve, reject) => {
        wx.requestPayment({
            timeStamp: timeStamp,
            nonceStr: nonceStr,
            package: prepay_id,
            signType: 'MD5',
            paySign: paySign,
            success(res) {
                console.log(res);
                resolve(res)
            },
            fail(err) {
                console.log(err);
                reject(err)
            }
        })
    })
};

export const getReqIndex = () => {
  return reqIndex - 1;
};

export const setTrackID = (id) => {
  trackID = id;
};

// 追加 query 到 url
const appendQuery = (url, key, value) => {
  key = encodeURIComponent(key);
  value = encodeURIComponent(value);
  if (!url) {
    return `?${key}=${value}`;
  }

  if (url.indexOf("?") === -1) {
    return url + `?${key}=${value}`;
  } else {
    return url + `&${key}=${value}`;
  }
};
