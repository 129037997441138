import * as utils from '@/utils/utils';

export const BAG_TYPE_NORMAL = 1;
export const BAG_TYPE_SUPER1 = 2;
export const BAG_TYPE_SUPER2 = 3;
export const BAG_TYPE_PLUS = 4;
export const BAG_TYPE_SCRATCH = 5;

export const BAG_STATUS_NEW = 1;
export const BAG_STATUS_IN_SALE = 2;
export const BAG_STATUS_FINISH = 3;
export const BAG_STATUS_DEL = 4;
export const BAG_STATUS_ABORT = 5;
export const BAG_STATUS_ABORT_REFUND = 6;
export const BAG_STATUS_ABORT_DISPATCH_RANKS = 7;

// 支付状态: 0=未付款  1=已付款  2=退款中  3=已退款  4=已取消
export const PAY_STATUS_NEW = 0;
export const PAY_STATUS_PAYED = 1;
export const PAY_STATUS_REFUNDING = 2;
export const PAY_STATUS_REFUNDED = 3;
export const PAY_STATUS_CANCELED = 4;
export const PAY_STATUS_PAYED_INVALID = 5;

export let BagType = utils.getParam('bag_type') || BAG_TYPE_NORMAL;

// batch params change callback
function batchParamsChangeCb() {
  // re-calc params
  BagType = utils.getParam('bag_type') || BAG_TYPE_NORMAL;
}
utils.setBatchParamsChangeCb(batchParamsChangeCb);
