import {infos as bbAdmin } from '@/powder/user/scms/blindbox/bag';
import * as utils from '@/utils/utils';
import * as constants from '@/config/constant';

export function getRoundInfo(bag) {
  const count_per_round = bag['total'] / bag['total_round'];
  return {
    count_per_round,
    cur_round: Math.floor((bag['total'] - bag['leftover']) / count_per_round),
  }
}

export async function waitSuperBagToFinished(bag_id) {
  if (constants.BagType === constants.BAG_TYPE_SUPER1) {
    let statusOK = false;
    // 超级福袋需要等待选择位置，状态变更为完成
    for (let i = 0; i < 10; i++) {
      await utils.sleep(1000);
      let info = await bbAdmin.info.make('args', bag_id);
      if (info.data.data.status === constants.BAG_STATUS_FINISH) {
        statusOK = true;
        break;
      }
    }
    utils.assert(statusOK, "超级福袋状态没有变更为完成");
  }
}
