import * as utils from "@/utils/utils";
import { infos as client } from "@/powder/user/client/index";

export function assertOK(ctx, info) {
  utils.assert(info.data.code === 200, "errno !== 200");
}

export function assertParamEmpty(ctx, info) {
  utils.assert([10000].indexOf(info.data.code) !== -1, "参数非空验证失败");
}

export function assertError(ctx, info) {
  utils.assert(info.data.code !== 200, "errno === 200");
}

export async function assertConch(expectedConch, msg, waitCount = 5) {
  // 不能用异步验证，避免其他操作影响贝壳值
  let conch;
  let suc = false;
  for (let i = 0; i < waitCount; i++) {
    await utils.sleep(1000);
    let res = await client.getUserInfo.make();
    conch = res.data.data.info.conch;
    if (expectedConch === conch) {
      suc = true;
      break;
    }
  }
  let info = msg + ", expectedConch：" + expectedConch + ", current：" + conch;
  utils.assert(suc, info);
}
