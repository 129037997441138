import { dajxHttp } from "../../../utils/request";
const post = dajxHttp.post;

/**
 * @desc 创建福袋订单
 *
 * @param type 福袋类型：1 常规福袋，2 超级福袋的一级福袋，3 超级福袋的二级福袋
 * @param status 状态：1 新建，2 售卖中，3 完成，5 终止
 * @param conch_first 优先贝壳支付
 * @returns {Promise<*>} {
  "code": 1,
  "message": "success!",
  "data": {
    "info": {
      "order_no": "B1061992395",
      "id": 167
    }
  }
}
 */
export function create(bag_id, round, position, conch_first) {
  let params = {
    url: "/api/blindbox/order/create",
    data: {
      bag_id, round, position, conch_first
    }
  };
  return post(params);
}

/**
 * @desc  支付福袋订单
 * @param order_no 订单编号(主订单编号|子订单编号)
 * @param pay_type 状态:0=微信支付 1=支付宝 2=微信小程序 3:微信H5支付 4:微信公众号支付
 * @param wx_code 微信网页授权code(支付方式为微信公众号支付必传,其他支付方式可以不传)
 * @param wx_return_url 微信H5支付回调地址(本参数仅在支付方式为微信H5支付时有效,其他支付方式可以不传)
 * @returns {Promise<*>}
 {
  "code": 1,
  "message": "success!",
  "data": {
    "info": {
      // 订单编号(价格改变后变化)
      "order_no": "P1085803086",
      // 订单支付金额
      "pay_amount": 1,
      // 支付参数
      "pay_info": {
        "appId": "wx3b78934b9b3265c1",
        "timeStamp": "1690474282",
        "nonceStr": "Dlgaceab1eYUJFOg",
        "package": "prepay_id=wx280011231043168f777570ee31a0270000",
        "signType": "MD5",
        "paySign": "7957BB9724CF8B2095EB1D7471C06EE1"
      }
    }
  }
}
 */
export async function pay(order_no,pay_type,wx_code, wx_return_url) {
  return await post({
    url: '/api/blindbox/order/pay',
    data: {order_no,pay_type,wx_code, wx_return_url}
  });
}

/**
 * @desc 订单列表
 *
 * @param page
 * @param page_size
 * @param type 订单所属的福袋类型：1 常规福袋，2 超级福袋的一级福袋，3 超级福袋的二级福袋，4 加加乐，5 刮刮乐
 * @param pay_status 支付状态: -1=全部  0=未付款  1=已付款  2=退款中  3=已退款  4=已取消
 * @returns {
  "code": 1,
  "message": "success!",
  "data": {
    "__info": "k",
    "info": [
      {
        "id": "165",
        "wx_no": "633329443",
        "order_no": "B1059536745",
        "pay_type": "2",
        "amount": "100",
        "bag_id": "117",
        "round": "0",
        "position": "2",
        "pay_status": "1",
        "cancel_type": "0",
        "create_time": "1682408520",
        "update_time": "1682408520",
        "pay_status_desc": "已付款",
        "bag": {
          "id": "117",
          "name": "福袋",
          "type": "1",
          "price": "100",
          "img": "https://admin.bb.uninote.com.cn/oss?path=bb/images/mh03.jpg",
          "sale_type": "1",
          "status": "3",
          "desc": "福袋描述",
          "status_desc": "完成"
        },
        "__boxes": "k",
        "boxes": [
          {
            "id": "233",
            "name": "血色玫瑰",
            "price": 120,
            "img": "https://admin.bb.uninote.com.cn/oss?path=bb/images/mh03.jpg",
            "condition": 1,
            "conch_value": 90
          }
        ]
      },
    ]
  }
}
 */
export async function list(page, page_size, type, pay_status) {
  let params = {
    url: "/api/blindbox/order/list",
    data: {
      page,
      page_size,
      type,
      pay_status,
    }
  };
  return post(params);
}

/**
 * @desc 订单详情，结构基本同 list 接口
 *
 * @param id
 * @returns {Promise<*>} {
  "code": 1,
  "message": "success!",
  "data": {
    "info": {
      "id": "39",
      "wx_no": "226551349",
      "order_no": "B1070062869",
      "pay_type": "2",
      "amount": "100",
      "bag_id": "24",
      "round": "0",
      "position": "0",
      "pay_status": "1",
      "cancel_type": "0",
      "create_time": "1682751132",
      "update_time": "1682751132",
      "pay_status_desc": "已付款",
      "bag": {
        "id": "24",
        "name": "福袋",
        "type": "1",
        "price": "100",
        "img": "https://admin.bb.uninote.com.cn/oss?path=bb/images/mh03.jpg",
        "sale_type": "1",
        "status": "3",
        "desc": "福袋描述",
        "status_desc": "完成"
      },
      "__boxes": "k",
      "boxes": [
        {
          "id": "24",
          "name": "血色茉莉",
          "price": 100,
          "img": "https://admin.bb.uninote.com.cn/oss?path=bb/images/mh03.jpg",
          "condition": 1,
          "conch_value": 0
        }
      ]
    }
  }
}
 */
export async function info(id) {
  let params = {
    url: "/api/blindbox/order/info",
    data: {
      id,
    }
  };
  return post(params);
}

/**
 * @desc  取消订单
 * @param order_no 订单编号(子订单编号)
 * @returns {Promise<*>}
{
  "code": 1,
  "message": "success!",
  "data": {
    "info": "ok"
  }
}
 */
export async function cancel(order_no) {
  return await post({
    url: '/api/blindbox/order/cancel',
    data: {order_no}
  });
}

/**
 * @desc 二级福袋选择位置
 *
 * @param bag_id
 * @param sub_bag_id
 * @param positions 如果为空，则自动的将所有资格随机选择完
 * @returns {Promise<*>} {
  "code": 1,
  "message": "success!",
  "data": {
    "info": {
      "__already_chosen": "k",
      "already_chosen": [
        1
      ],
      suc_count: 1,
    }
  }
}
 */
export async function subBagChoose(bag_id, sub_bag_id, positions) {
  return await post({
    url: '/api/blindbox/order/sub-bag-choose',
    data: {bag_id, sub_bag_id, positions}
  });
}

/**
 * @desc 加入自动选择队列，超级福地部分退款完成后调用
 *
 * @param bag_id
 * @returns {Promise<*>} {
  "code": 1,
  "message": "success!",
  "data": {
    "info": {
      "push_result": "13"
    }
  }
}
 */
export async function pushChooseJob(bag_id) {
  return await post({
    url: '/api/blindbox/order/push-choose-job',
    data: {bag_id}
  });
}
