/** ======================================= 注意，此文件为自动生成，不要手动修改！！================================ **/
import {base} from '../../base';

export const infos = {
  'wxTel': {
    '__proto__': base,
    'name': 'wxTel',
    'desc': "微信登录绑定手机号",
    'url': '/api/user/wx-bind-mobile',
    'args': {
      openid: {
        'pos': 0,
        'desc': "前端通过[user/wx-login]微信小程序登录接口获取openid",
        'demo': '',
      },
      session_key: {
        'pos': 1,
        'desc': "前端通过[user/wx-login]微信小程序登录接口获取session_key",
        'demo': '',
      },
      encrypted_data: {
        'pos': 2,
        'desc': "前端通过getPhoneNumber获取的encryptedData",
        'demo': '',
      },
      iv: {
        'pos': 3,
        'desc': "前端通过getPhoneNumber获取的iv",
        'demo': '',
      },
      invite_code: {
        'pos': 4,
        'desc': "邀请码",
        'demo': '',
      },

    },
    'returns': "{Promise<any>}\n{\n   \"code\": 1,\n   \"message\": \"success!\",\n   \"__data\": \"i\",\n   \"data\": {\n       \"info\": {\n           \"token\": \"67307ca2928047e78c1e3e4a49d41494\"\n       }\n   }\n}\n",
  },
  'wxLogin': {
    '__proto__': base,
    'name': 'wxLogin',
    'desc': "微信小程序登录",
    'url': '/api/user/wx-login',
    'args': {
      code: {
        'pos': 0,
        'desc': "微信登录凭证,小程序调用wx.login方法获取的code值",
        'demo': '',
      },
      nick_name: {
        'pos': 1,
        'desc': "微信昵称,前端通过wx.getUserInfo获取的nickName值",
        'demo': '',
      },
      avatar_url: {
        'pos': 2,
        'desc': "微信头像,小程序调用wx.login方法获取的avatarUrl值",
        'demo': '',
      },
      signature: {
        'pos': 3,
        'desc': "签名",
        'demo': '',
      },
      encrypted_data: {
        'pos': 4,
        'desc': "数据",
        'demo': '',
      },
      iv: {
        'pos': 5,
        'desc': "加密",
        'demo': '',
      },

    },
    'returns': "{Promise<any>}\n{\n\t\"code\": 1,\n\t\"message\": \"success!\",\n\t\"__data\": \"i\",\n\t\"data\": {\n\t\t\"info\": {\n\t\t    // 登录凭证,前端根据返回的token是否为空决定是否需要绑定手机号,token为空就需要绑定手机号\n\t\t\t\"token\": \"7171c1b341ff66d705db12b49947c0fc\",\n\t\t\t// 微信用户唯一标识\n\t\t\t\"openid\": \"\",\n\t\t\t// 微信登录的session_key\n\t\t\t\"session_key\": \"\"\n\t\t}\n\t}\n}\n",
  },
  'rotationPicture': {
    '__proto__': base,
    'name': 'rotationPicture',
    'desc': "轮播图数据接口",
    'url': '/api/banner/model',
    'args': {
      modelID: {
        'pos': 0,
        'desc': "array 轮播图模块ID以数组的方式",
        'demo': '',
      },
      adCode: {
        'pos': 1,
        'desc': "string 城市代码",
        'demo': '',
      },

    },
    'returns': "{Promise<any>}\n{\n \"code\": 1,\n \"message\": \"success!\",\n \"__data\": \"i\",\n \"data\": {\n   \"info\": [\n     {\n       \"id\": \"21\",\n       \"pid\": \"0\",\n       \"title\": \"首页 - 顶部\",\n       \"status\": \"1\",\n       \"sort\": \"4\",\n       // 图片地址\n       \"img_src\": \"https://admin.bb.uninote.com.cn/oss?path=img/5c9641b1f19e8089c4c270a97c2e5834.jpg\",\n       \"model_id\": \"1\",\n       // 透传参数\n       \"payload\": {\n         \"type\": 20,\n         \"id\": \"https://www.dajxyl.com/share/\"\n       },\n       // 所属城市\n       \"ad_code\": \"000000\",\n       \"city_id\": \"0\"\n     }\n   ]\n }\n}\n",
  },
  'getAddressList': {
    '__proto__': base,
    'name': 'getAddressList',
    'desc': "获取用户地址",
    'url': '/api/address/get',
    'args': {

    },
    'returns': "{Promise<any>}\n{\n \"code\": 1,\n \"message\": \"success!\",\n \"__data\": \"i\",\n \"data\": {\n   \"info\": [\n     {\n       \"id\": \"573\",\n       // 用户ID\n       \"uid\": \"10763\",\n       // 收货人\n       \"name\": \"name0.8797652677029066\",\n       // 城市代码\n       \"adCode\": \"510100\",\n       // 详细地址\n       \"desc\": \"环球中心W6\",\n       // 联系电话\n       \"tel\": \"13886347508\",\n       // 所属省份\n       \"province\": \"四川省\",\n       // 所属城市\n       \"city\": \"成都市\",\n       // 所属地区\n       \"area\": \"武侯区\",\n       // 状态(单选):0=否,1=是\n       \"is_default\": \"1\",\n       // 经度\n       \"lng\": \"104.060824\",\n       // 纬度\n       \"lat\": \"30.567234\",\n       // 门牌号\n       \"doorplate\": \"11\",\n       // 所属省份ID\n       \"province_city_id\": \"360\",\n       // 所属省份adcode\n       \"province_city_key\": \"510000\"\n     }\n   ]\n }\n}\n",
  },
  'sefDefaultAddress': {
    '__proto__': base,
    'name': 'sefDefaultAddress',
    'desc': "设置默认用户地址",
    'url': '/api/address/setdefault',
    'args': {
      addressId: {
        'pos': 0,
        'desc': "地址的ID",
        'demo': '',
      },

    },
    'returns': "{Promise<any>}\n",
  },
  'getUserInfo': {
    '__proto__': base,
    'name': 'getUserInfo',
    'desc': "获取用户信息",
    'url': '/api/user/info',
    'args': {

    },
    'returns': "{Promise<any>}\n{\n \"code\": 1,\n \"message\": \"success!\",\n \"__data\": \"i\",\n \"data\": {\n   \"info\": {\n       // 用户id\n     \"uid\": 10763,\n     // 用户昵称\n     \"nickname\": \"\",\n     // 用户出生年月日\n     \"birthday\": null,\n     // 用户性别 0女，1男\n     \"sex\": null,\n     // 头像\n     \"pic\": \"https://admin.bb.uninote.com.cn/oss?path=img/default_pic.png\",\n     // 用户手机号码\n     \"phone\": \"18111619321\",\n     // 积分金币\n     \"integral\": 0,\n     // 积分金币累积的数量\n     \"integral_accumulated\": 0,\n     // 贝壳余额\n     \"conch\": 0\n   }\n }\n}\n",
  },
  'getDefaultAddress': {
    '__proto__': base,
    'name': 'getDefaultAddress',
    'desc': "获取用户默认地址",
    'url': '/api/address/default',
    'args': {

    },
    'returns': "{Promise<any>}\n{\n \"code\": 1,\n \"message\": \"success!\",\n \"__data\": \"i\",\n \"data\": {\n   \"info\": {\n     \"id\": \"573\",\n     // 用户ID\n     \"uid\": \"10763\",\n     // 收货人\n     \"name\": \"name0.8797652677029066\",\n     // 城市代码\n     \"adCode\": \"510100\",\n     // 详细地址\n     \"desc\": \"环球中心W611\",\n     // 联系电话\n     \"tel\": \"13886347508\",\n     // 所属省份\n     \"province\": \"四川省\",\n     // 所属城市\n     \"city\": \"成都市\",\n     // 状态(单选):0=否,1=是\n     \"is_default\": \"1\",\n     // 经度\n     \"lng\": \"104.060824\",\n     // 纬度\n     \"lat\": \"30.567234\",\n     // 所属省份ID\n     \"province_city_id\": \"360\",\n     // 所属省份adcode\n     \"province_city_key\": \"510000\"\n   }\n }\n}\n",
  },
  'codeInvitation': {
    '__proto__': base,
    'name': 'codeInvitation',
    'desc': "获取分享邀请码",
    'url': '/api/user/invite',
    'args': {
      phone: {
        'pos': 0,
        'desc': "电话号码",
        'demo': '',
      },

    },
    'returns': "{Promise<any>}\n{\n \"code\": 1,\n \"message\": \"success!\",\n \"__data\": \"i\",\n \"data\": {\n   \"info\": {\n     // 邀请码\n     \"code\": \"1J6FHV\"\n   }\n }\n}\n",
  },
  'wxpay': {
    '__proto__': base,
    'name': 'wxpay',
    'desc': "微信付款",
    'url': '/api/pay/wx',
    'args': {
      orderID: {
        'pos': 0,
        'desc': "订单编号(不是订单ID,是订单编号)",
        'demo': '',
      },
      type: {
        'pos': 1,
        'desc': "支付方式 （默认）1：微信APP 支付 2：微信小程序支付 3：微信H5支付 4:微信公众号支付",
        'demo': '',
      },
      wx_code: {
        'pos': 2,
        'desc': "微信网页授权code(支付方式为微信公众号支付必传,其他支付方式可以不传)",
        'demo': '',
      },
      wx_return_url: {
        'pos': 3,
        'desc': "微信H5支付回调地址(本参数仅在支付方式为微信H5支付时有效,其他支付方式可以不传)",
        'demo': '',
      },

    },
    'returns': "{Promise<any>}\n{\n \"code\": 1,\n \"message\": \"success!\",\n \"data\": {\n   \"__info\": \"i\",\n   \"info\": {\n     \"code\": 1,\n     \"error\": \"\",\n     \"info\": {\n       \"appid\": \"wx1288370bcddf64a6\",\n       \"partnerid\": \"1561352391\",\n       \"prepayid\": \"wx06133735377212029ee10fa58dbe2f0000\",\n       \"timestamp\": \"1630906655\",\n       \"noncestr\": \"agi9MHXoIltR8OBj\",\n       \"package\": \"Sign=WXPay\",\n       \"sign\": \"A4CF95771AD8C7D9CCF80514868415D6\"\n     },\n     \"status\": true\n   }\n }\n}\n",
  },
  'addAddress': {
    '__proto__': base,
    'name': 'addAddress',
    'desc': "添加新服务地址接口",
    'url': '/api/address/set',
    'args': {
      name: {
        'pos': 0,
        'desc': "",
        'demo': '',
      },
      adCode: {
        'pos': 1,
        'desc': "",
        'demo': '',
      },
      desc: {
        'pos': 2,
        'desc': "",
        'demo': '',
      },
      doorplate: {
        'pos': 3,
        'desc': "门牌号信息",
        'demo': '',
      },
      tel: {
        'pos': 4,
        'desc': "",
        'demo': '',
      },
      province: {
        'pos': 5,
        'desc': "",
        'demo': '',
      },
      city: {
        'pos': 6,
        'desc': "",
        'demo': '',
      },
      area: {
        'pos': 7,
        'desc': "",
        'demo': '',
      },
      lng: {
        'pos': 8,
        'desc': "",
        'demo': '',
      },
      lat: {
        'pos': 9,
        'desc': "",
        'demo': '',
      },
      is_default: {
        'pos': 10,
        'desc': "",
        'demo': '',
      },

    },
    'returns': "{Promise<any>}\n{\n \"code\": 1,\n \"message\": \"success!\",\n \"__data\": \"i\",\n \"data\": {\n   // 地址ID\n   \"info\": \"585\"\n }\n}\n",
  },
  'updateUserData': {
    '__proto__': base,
    'name': 'updateUserData',
    'desc': "设置页面提交用户修改完成的数据",
    'url': '/api/user/updateinfo',
    'args': {
      birthday: {
        'pos': 0,
        'desc': "",
        'demo': '',
      },
      nickname: {
        'pos': 1,
        'desc': "",
        'demo': '',
      },
      phone: {
        'pos': 2,
        'desc': "",
        'demo': '',
      },
      sex: {
        'pos': 3,
        'desc': "",
        'demo': '',
      },
      pic: {
        'pos': 4,
        'desc': "",
        'demo': '',
      },

    },
    'returns': "{Promise<any>}\n",
  },
  'updateUserImg': {
    '__proto__': base,
    'name': 'updateUserImg',
    'desc': "",
    'url': '/api/upload/image',
    'args': {
      file_base64: {
        'pos': 0,
        'desc': "图片文件base64值",
        'demo': '',
      },
      scene: {
        'pos': 1,
        'desc': "场景 (默认空, 不验证图片大小及尺寸,根据不同场景对图片的尺寸和大小限制不一样)\n可选值：\nshop_admin_product_image_1：商城管理后台上传商品入户图场景\nshop_admin_product_image_2：商城管理后台上传商品banner图场景\nshop_admin_product_category_image_1：商城管理后台上传商品分类图场景\nshop_admin_promotion_category_image_1：商城管理后台上传促销活动分类图片场景",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"code\": 1,\n \"message\": \"success!\",\n \"data\": {\n   \"info\": [\n     {\n       // 图片可访问地址\n       \"url\": \"https://admin.bb.uninote.com.cn/oss?path=uploads/images/2021/09/647c3f605c39d3dc967ed6768cab9717.png\",\n       // 图片KEY值\n       \"file_key\": \"647c3f605c39d3dc967ed6768cab9717\"\n     }\n   ]\n }\n}\n",
  },
  'uploadUrlImg': {
    '__proto__': base,
    'name': 'uploadUrlImg',
    'desc': "图片上传，通过 url\n",
    'url': '/api/upload/url-image',
    'args': {
      url: {
        'pos': 0,
        'desc': "图片 url",
        'demo': '',
      },

    },
    'returns': "{Promise<*>} {\n \"code\": 1,\n \"message\": \"success!\",\n \"data\": {\n   \"info\": {\n     \"url\": \"https://admin.bb.uninote.com.cn/oss?path=uploads/images/2023/04/469f5fc80587808937e2d6e2e34a4da4.jpg\"\n   }\n }\n}\n",
  },
  'deleteAddress': {
    '__proto__': base,
    'name': 'deleteAddress',
    'desc': "用户删除地址",
    'url': '/api/address/del',
    'args': {
      id: {
        'pos': 0,
        'desc': "地址ID",
        'demo': '',
      },

    },
    'returns': "{Promise<any>}\n",
  },
  'editAddress': {
    '__proto__': base,
    'name': 'editAddress',
    'desc': "修改用户地址",
    'url': '/api/address/edit',
    'args': {
      id: {
        'pos': 0,
        'desc': "地址ID",
        'demo': '',
      },
      name: {
        'pos': 1,
        'desc': "名称",
        'demo': '',
      },
      adCode: {
        'pos': 2,
        'desc': "城市ID",
        'demo': '',
      },
      desc: {
        'pos': 3,
        'desc': "详情描述",
        'demo': '',
      },
      doorplate: {
        'pos': 4,
        'desc': "门牌号信息",
        'demo': '',
      },
      tel: {
        'pos': 5,
        'desc': "电话号码",
        'demo': '',
      },
      province: {
        'pos': 6,
        'desc': "省",
        'demo': '',
      },
      city: {
        'pos': 7,
        'desc': "城市",
        'demo': '',
      },
      area: {
        'pos': 8,
        'desc': "区",
        'demo': '',
      },
      lng: {
        'pos': 9,
        'desc': "经度",
        'demo': '',
      },
      lat: {
        'pos': 10,
        'desc': "纬度",
        'demo': '',
      },
      is_default: {
        'pos': 11,
        'desc': "是否默认 0 否 1 是",
        'demo': '',
      },

    },
    'returns': "{Promise<any>}\n",
  },
  'loginVerify': {
    '__proto__': base,
    'name': 'loginVerify',
    'desc': "不知道哪冒出来的方法接口 @fixme",
    'url': 'undefined',
    'args': {
      parma: {
        'pos': 0,
        'desc': "",
        'demo': '',
      },

    },
    'returns': "{Promise<any>}\n",
  },
  'telForCode': {
    '__proto__': base,
    'name': 'telForCode',
    'desc': "发送验证码",
    'url': '/api/code/get',
    'args': {
      phone: {
        'pos': 0,
        'desc': "",
        'demo': '',
      },
      scenariors: {
        'pos': 1,
        'desc': "场景\n register 注册场景\n login 验证码登录场景\n forget 忘记密码场景\n beadhouse 康养中心托管申请场景\n beadhouseMan 康养中心申请入住场景\n volunteer 志愿者申请场景\n rescue 慈善捐助\n verify_tel 验证原有手机\n update_tel 修改手机号\n update_pass 修改密码",
        'demo': '',
      },

    },
    'returns': "{Promise<any>}\n",
  },
  'telByCodeGetToken': {
    '__proto__': base,
    'name': 'telByCodeGetToken',
    'desc': "用户登录 - 验证码登录",
    'url': '/api/user/loginbycode',
    'args': {
      phone: {
        'pos': 0,
        'desc': "手机号",
        'demo': '',
      },
      verifyCode: {
        'pos': 1,
        'desc': "短信验证码",
        'demo': '',
      },
      invite_code: {
        'pos': 2,
        'desc': "邀请码",
        'demo': '',
      },
      cid: {
        'pos': 3,
        'desc': "个推设备唯一ID",
        'demo': '',
      },
      cityCode: {
        'pos': 4,
        'desc': "城市code 默认0：全国",
        'demo': '',
      },

    },
    'returns': "{Promise<any>} {\n \"code\": 1,\n \"message\": \"success!\",\n \"data\": {\n   \"token\": \"a7b18a0ac3bf80cae9ed9babeb8cab97\",\n   \"__id\": \"t\",\n   \"id\": 11693\n }\n}\n",
  },
  'testNotifyWX': {
    '__proto__': base,
    'name': 'testNotifyWX',
    'desc': "微信支付回调（仅用于测试）",
    'url': 'undefined',
    'args': {
      orderID: {
        'pos': 0,
        'desc': "",
        'demo': '',
      },
      wx_no: {
        'pos': 1,
        'desc': "微信订单号\n",
        'demo': '',
      },

    },
    'returns': "",
  },
  'home_config': {
    '__proto__': base,
    'name': 'home_config',
    'desc': "获取 App 配置",
    'url': '/api/home/config',
    'args': {

    },
    'returns': "{Promise<*>}\n{\n \"code\": 1,\n \"message\": \"success!\",\n \"__data\": \"i\",\n \"data\": {\n   \"info\": {\n     // 服务热线\n     \"service_tel\": \"‭400 036 0036‬\",\n     // 用户服务协议\n     \"User_service_agreement\": \"https://admin.bb.uninote.com.cn/privacy/index.html\"\n   },\n   \"status\": true\n }\n}\n",
  },
  'user_invite_group_info': {
    '__proto__': base,
    'name': 'user_invite_group_info',
    'desc': "获取邀请人数页面聚合数据",
    'url': '/api/user/invite-group-info',
    'args': {
      phone: {
        'pos': 0,
        'desc': "手机号码",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n\t\"code\": 1,\n\t\"message\": \"success!\",\n\t\"__data\": \"i\",\n\t\"data\": {\n\t\t\"info\": {\n\t\t    // 邀请人数\n\t\t\t\"invited_num\": 2,\n\t\t\t\"__invited_parent\": \"i\",\n\t\t\t\"invited_parent\": {\n\t\t\t    // 邀请人姓名\n\t\t\t\t\"nickname\": null,\n\t\t\t\t// 邀请人电话\n\t\t\t\t\"phone\": \"13575386590\"\n\t\t\t},\n\t\t\t\"__invited_child\": \"i\",\n\t\t\t\"invited_child\": [\n\t\t\t\t{\n\t\t\t\t    // 姓名\n\t\t\t\t\t\"nickname\": null,\n\t\t\t\t\t// 电话\n\t\t\t\t\t\"phone\": \"18898529172\",\n\t\t\t\t\t// 创建时间\n\t\t\t\t\t\"create_time\": \"2020-08-27 14:44:42\"\n\t\t\t\t}\n\t\t\t]\n\t\t}\n\t}\n}\n",
  },
  'user_add_inviter': {
    '__proto__': base,
    'name': 'user_add_inviter',
    'desc': "添加我的邀请者",
    'url': '/api/user/add-inviter',
    'args': {
      phone: {
        'pos': 0,
        'desc': "手机号码",
        'demo': '',
      },
      invite_code: {
        'pos': 1,
        'desc': "邀请码",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n",
  },
  'user_get_me_invited': {
    '__proto__': base,
    'name': 'user_get_me_invited',
    'desc': "获取我邀请的好友列表",
    'url': '/api/user/get-me-invited',
    'args': {
      phone: {
        'pos': 0,
        'desc': "手机号码",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n\t\"code\": 1,\n\t\"message\": \"success!\",\n\t\"__data\": \"i\",\n\t\"data\": {\n\t\t\"info\": {\n\t\t\t\"__invited_child\": \"k\",\n\t\t\t\"invited_child\": [\n\t\t\t\t{\n\t\t\t\t\t // 姓名\n\t\t\t\t\t\"nickname\": null,\n\t\t\t\t\t// 电话\n\t\t\t\t\t\"phone\": \"18898529172\",\n\t\t\t\t\t// 创建时间\n\t\t\t\t\t\"create_time\": \"2020-08-27 14:44:42\"\n\t\t\t\t}\n\t\t\t]\n\t\t}\n\t}\n}\n",
  },
  'user_invite_friend': {
    '__proto__': base,
    'name': 'user_invite_friend',
    'desc': "点击邀请好友聚合接口",
    'url': '/api/user/invite-friend',
    'args': {
      phone: {
        'pos': 0,
        'desc': "手机号码",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"code\": 1,\n \"message\": \"success!\",\n \"__data\": \"i\",\n \"data\": {\n   \"info\": {\n      // 链接\n     \"inviter_url\": \"https://api.dajxyl.com/share/?invite_code=1MZKZJ\",\n      // 邀请码\n     \"inviter_code\": \"1MZKZJ\"\n   }\n }\n}\n",
  },
  'user_invite': {
    '__proto__': base,
    'name': 'user_invite',
    'desc': "获取邀请码",
    'url': '/api/user/invite',
    'args': {
      phone: {
        'pos': 0,
        'desc': "手机号码",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"code\": 1,\n \"message\": \"success!\",\n \"__data\": \"i\",\n \"data\": {\n   \"info\": {\n     // 邀请码\n     \"code\": \"1MZKZJ\"\n   }\n }\n}\n",
  },
  'userCloseAccount': {
    '__proto__': base,
    'name': 'userCloseAccount',
    'desc': "注销账户",
    'url': '/api/user/close-account',
    'args': {

    },
    'returns': "{Promise<*>}\n{\n \"code\": 1,\n \"message\": \"success!\",\n \"data\": {}\n}\n",
  },
  'getWxCode': {
    '__proto__': base,
    'name': 'getWxCode',
    'desc': "获取微信公众号网页授权",
    'url': '/api/user/get-wx-code',
    'args': {
      wx_return_url: {
        'pos': 0,
        'desc': "微信H5支付回调地址(本参数仅在支付方式为微信H5支付时有效,其他支付方式可以不传)",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"code\": 1,\n \"message\": \"success!\",\n \"data\": {\n   \"info\": {\n     // 前端跳转此URL后可得到微信公众号网页授权code\n     \"url\": \"https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe5f7e1347984d353&redirect_uri=http%3A%2F%2Flocalhost%2F%3Fphone%3D18111619307%26staff_id%3D5%26phone_staff%3D13668213798%26passwd_staff%3D123456%26api%3Dclient%252Findex%26select%3DgetWxCode&response_type=code&scope=snsapi_base&state=wechat_redirect#wechat_redirect\"\n   }\n }\n}\n",
  },
  'getWxJsapiConfig': {
    '__proto__': base,
    'name': 'getWxJsapiConfig',
    'desc': "获取微信 JS-SDK 配置信息\n",
    'url': '/api/user/get-wx-jsapi-config',
    'args': {

    },
    'returns': "{Promise<*>} {\n \"code\": 1,\n \"message\": \"success!\",\n \"data\": {\n   \"info\": {\n     \"timeStamp\": \"1637493229\",\n     \"nonceStr\": \"MlFc3V4HKh2CIT8Z\",\n     \"url\": \"http://a.dajx.my:8081/?phone=18982283449&staff_id=13&phone_staff=13320817290&passwd_staff=123456&cmsphpsid=i0kflsl3jemc8vgm8bm0fo6qtj&debug=1&select-module=client%2Findex&select-api=getWxJsapiConfig\",\n     \"$signature\": \"9673d4f69ee833dc218f2b44eaa7299e3b18526a\"\n   }\n }\n}\n",
  },
  'visit': {
    '__proto__': base,
    'name': 'visit',
    'desc': "user visit report\n",
    'url': '/api/user/visit',
    'args': {
      visit_id: {
        'pos': 0,
        'desc': "存储于客户端的访问 unique id，如果不传，则由服务器生成",
        'demo': '',
      },
      source_id: {
        'pos': 1,
        'desc': "渠道 id",
        'demo': '',
      },

    },
    'returns': "{Promise<*>} {\n \"code\": 1,\n \"message\": \"success!\",\n \"data\": {\n   \"info\": {\n     \"visit_id\": \"61a8602dd0991\"\n   }\n }\n}\n",
  }
};
