import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../consts';
import {infos as base} from '../../generated/scms/user';
import * as apis from '../../../api/scms/user';
import {infos as bag} from "@/powder/user/client/blindbox/bag";
import {randomTel} from '@/utils/utils';
import {infos as client_index, getUserID} from '@/powder/user/client/index';
import {getToken, setToken} from '@/../service';

export const infos = {

  'getUserConchLog': {
    '__proto__': base.getUserConchLog,
    'api': apis.getUserConchLog,
    'desc': "获取用户贝壳明细列表",
    'url': '/scmsapi/user/getUserConchLog',
    'defaults': {
      page: 1,
      page_size: 10,
      uid: 10000,
      type: "",
      create_time_start: '',
      create_time_end: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            uid: await getUserID(),
          };
        }
      },
    ],
  },

  'exportUserConchLogList': {
    '__proto__': base.exportUserConchLogList,
    'api': apis.exportUserConchLogList,
    'desc': "导出用户贝壳明细列表(本接口请采用get请求，请求参数与/scmsapi/user/getUserConchLog接口的请求参数一致)",
    'url': '/scmsapi/user/exportUserConchLogList',
    'defaults': {
      page: 1,
      page_size: 10,
      uid: 10000,
      type: "",
      create_time_start: '',
      create_time_end: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'list': {
    '__proto__': base.list,
    'api': apis.list,
    'desc': "获取用户列表",
    'url': '/scmsapi/user/list',
    'defaults': {
      page: '',
      page_size: '',
      keyword: '',
      uid: '',
      name: '',
      phone: '',
      startTime: '',
      endTime: '',
      sex: '',
      status: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'edit': {
    '__proto__': base.edit,
    'api': apis.edit,
    'desc': "编辑用户信息",
    'url': '/scmsapi/user/edit',
    'defaults': {
      uid: '',
      nickname: '',
      remark_name: '',
      sex: '',
      birthday: '',
      phone: '',
      status: '',
      time: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          let res = (await infos.list.make()).data.data.data;
          let userInfo = res[0];
          ctx.generated = userInfo;
          ctx.generated.birthday = userInfo.birthday == "" || userInfo.birthday == null ? "" : Math.floor(Date.parse(userInfo.birthday) / 1000);
        }
      },
    ],
  },

  'info': {
    '__proto__': base.info,
    'api': apis.info,
    'desc': "获取用户信息",
    'url': '/scmsapi/user/info',
    'defaults': {
      uid: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          let res = (await infos.list.make()).data.data.data;
          let userInfo = res[0];
          ctx.generated = {
            uid: userInfo.uid,
          };
        }
      },
    ],
  },

  'getUserIntegralLog': {
    '__proto__': base.getUserIntegralLog,
    'api': apis.getUserIntegralLog,
    'desc': "获取用户积分明细列表",
    'url': '/scmsapi/user/getUserIntegralLog',
    'defaults': {
      page: '',
      page_size: '',
      uid: 10015,
      type: '',
      order_no: '',
      create_time_start: '',
      create_time_end: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          let res = (await infos.list.make()).data.data.data;
          let userInfo = res[0];
          ctx.generated = {
            uid: userInfo.uid,
          };
        }
      },
    ],
  },

  'del': {
    '__proto__': base.del,
    'api': apis.del,
    'desc': "删除用户",
    'url': '/scmsapi/user/del',
    'defaults': {
      uid: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'reg-del',
        'desc': '随机注册一个用户，再删除',
        'type': NORMAL,
        'func': async (ctx) => {
          // save the old token
          const oldToken = getToken();
          // 随机注册一个用户
          let info = await client_index.telByCodeGetToken.make('args', randomTel());
          setToken(info.data.data.token);
          info = await client_index.getUserInfo.make();
          // restore the old token
          setToken(oldToken);

          ctx.generated = {
            uid: info.data.data.info.uid,
          };
        }
      },
      {
        'scene': 'reg-del2',
        'desc': '随机注册一个用户，再删除，直接获取创建用户 id 方式',
        'type': NORMAL,
        'func': async (ctx) => {
          // 随机注册一个用户
          let info = await client_index.telByCodeGetToken.make('args', randomTel());
          ctx.generated = {
            uid: info.data.data.id,
          };
        }
      },
    ],
  },

  'exportUserList': {
    '__proto__': base.exportUserList,
    'api': apis.exportUserList,
    'desc': "导出用户列表(本接口请采用get请求，请求参数与/scmsapi/user/list接口的请求参数一致)",
    'url': '/scmsapi/user/exportUserList',
    'defaults': {
      page: '',
      page_size: '',
      keyword: '',
      uid: '',
      name: '',
      phone: '',
      startTime: '',
      endTime: '',
      sex: '',
      status: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'exportUserIntegralLogList': {
    '__proto__': base.exportUserIntegralLogList,
    'api': apis.exportUserIntegralLogList,
    'desc': "导出用户积分明细列表(本接口请采用get请求，请求参数与/scmsapi//user/getUserIntegralLog接口的请求参数一致)",
    'url': '/scmsapi/user/exportUserIntegralLogList',
    'defaults': {
      page: '',
      page_size: '',
      uid: 10015,
      type: '',
      order_no: '',
      create_time_start: '',
      create_time_end: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          let res = (await infos.list.make()).data.data.data;
          let userInfo = res[0];
          ctx.generated = {
            uid: userInfo.uid,
          };
        }
      },
    ],
  },

  'changeConch': {
    '__proto__': base.changeConch,
    'api': apis.changeConch,
    'desc': "管理员修改用户贝壳值\n",
    'url': '/scmsapi/user/changeConch',
    'defaults': {
      uid: 0,
      delta: 0,
      comment: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          let res = (await infos.list.make()).data.data.data;
          let userInfo = res[0];
          ctx.generated = {
            uid: userInfo.uid,
            delta: 10,
            comment: "powder 增加",
          };
        }
      },
    ],
  }
};
