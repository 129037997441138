/** ======================================= 注意，此文件为自动生成，不要手动修改！！================================ **/
import {base} from '../../../base';

export const infos = {
  'create': {
    '__proto__': base,
    'name': 'create',
    'desc': "创建福袋订单\n",
    'url': '/api/blindbox/order/create',
    'args': {
      bag_id: {
        'pos': 0,
        'desc': "",
        'demo': '',
      },
      round: {
        'pos': 1,
        'desc': "",
        'demo': '',
      },
      position: {
        'pos': 2,
        'desc': "",
        'demo': '',
      },
      conch_first: {
        'pos': 3,
        'desc': "优先贝壳支付",
        'demo': '',
      },

    },
    'returns': "{Promise<*>} {\n \"code\": 1,\n \"message\": \"success!\",\n \"data\": {\n   \"info\": {\n     \"order_no\": \"B1061992395\",\n     \"id\": 167\n   }\n }\n}\n",
  },
  'pay': {
    '__proto__': base,
    'name': 'pay',
    'desc': " 支付福袋订单",
    'url': '/api/blindbox/order/pay',
    'args': {
      order_no: {
        'pos': 0,
        'desc': "订单编号(主订单编号|子订单编号)",
        'demo': '',
      },
      pay_type: {
        'pos': 1,
        'desc': "状态:0=微信支付 1=支付宝 2=微信小程序 3:微信H5支付 4:微信公众号支付",
        'demo': '',
      },
      wx_code: {
        'pos': 2,
        'desc': "微信网页授权code(支付方式为微信公众号支付必传,其他支付方式可以不传)",
        'demo': '',
      },
      wx_return_url: {
        'pos': 3,
        'desc': "微信H5支付回调地址(本参数仅在支付方式为微信H5支付时有效,其他支付方式可以不传)",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"code\": 1,\n \"message\": \"success!\",\n \"data\": {\n   \"info\": {\n     // 订单编号(价格改变后变化)\n     \"order_no\": \"P1085803086\",\n     // 订单支付金额\n     \"pay_amount\": 1,\n     // 支付参数\n     \"pay_info\": {\n       \"appId\": \"wx3b78934b9b3265c1\",\n       \"timeStamp\": \"1690474282\",\n       \"nonceStr\": \"Dlgaceab1eYUJFOg\",\n       \"package\": \"prepay_id=wx280011231043168f777570ee31a0270000\",\n       \"signType\": \"MD5\",\n       \"paySign\": \"7957BB9724CF8B2095EB1D7471C06EE1\"\n     }\n   }\n }\n}\n",
  },
  'list': {
    '__proto__': base,
    'name': 'list',
    'desc': "订单列表\n",
    'url': '/api/blindbox/order/list',
    'args': {
      page: {
        'pos': 0,
        'desc': "",
        'demo': '',
      },
      page_size: {
        'pos': 1,
        'desc': "",
        'demo': '',
      },
      type: {
        'pos': 2,
        'desc': "订单所属的福袋类型：1 常规福袋，2 超级福袋的一级福袋，3 超级福袋的二级福袋，4 加加乐，5 刮刮乐",
        'demo': '',
      },
      pay_status: {
        'pos': 3,
        'desc': "支付状态: -1=全部  0=未付款  1=已付款  2=退款中  3=已退款  4=已取消",
        'demo': '',
      },

    },
    'returns': "{\n \"code\": 1,\n \"message\": \"success!\",\n \"data\": {\n   \"__info\": \"k\",\n   \"info\": [\n     {\n       \"id\": \"165\",\n       \"wx_no\": \"633329443\",\n       \"order_no\": \"B1059536745\",\n       \"pay_type\": \"2\",\n       \"amount\": \"100\",\n       \"bag_id\": \"117\",\n       \"round\": \"0\",\n       \"position\": \"2\",\n       \"pay_status\": \"1\",\n       \"cancel_type\": \"0\",\n       \"create_time\": \"1682408520\",\n       \"update_time\": \"1682408520\",\n       \"pay_status_desc\": \"已付款\",\n       \"bag\": {\n         \"id\": \"117\",\n         \"name\": \"福袋\",\n         \"type\": \"1\",\n         \"price\": \"100\",\n         \"img\": \"https://admin.bb.uninote.com.cn/oss?path=bb/images/mh03.jpg\",\n         \"sale_type\": \"1\",\n         \"status\": \"3\",\n         \"desc\": \"福袋描述\",\n         \"status_desc\": \"完成\"\n       },\n       \"__boxes\": \"k\",\n       \"boxes\": [\n         {\n           \"id\": \"233\",\n           \"name\": \"血色玫瑰\",\n           \"price\": 120,\n           \"img\": \"https://admin.bb.uninote.com.cn/oss?path=bb/images/mh03.jpg\",\n           \"condition\": 1,\n           \"conch_value\": 90\n         }\n       ]\n     },\n   ]\n }\n}\n",
  },
  'info': {
    '__proto__': base,
    'name': 'info',
    'desc': "订单详情，结构基本同 list 接口\n",
    'url': '/api/blindbox/order/info',
    'args': {
      id: {
        'pos': 0,
        'desc': "",
        'demo': '',
      },

    },
    'returns': "{Promise<*>} {\n \"code\": 1,\n \"message\": \"success!\",\n \"data\": {\n   \"info\": {\n     \"id\": \"39\",\n     \"wx_no\": \"226551349\",\n     \"order_no\": \"B1070062869\",\n     \"pay_type\": \"2\",\n     \"amount\": \"100\",\n     \"bag_id\": \"24\",\n     \"round\": \"0\",\n     \"position\": \"0\",\n     \"pay_status\": \"1\",\n     \"cancel_type\": \"0\",\n     \"create_time\": \"1682751132\",\n     \"update_time\": \"1682751132\",\n     \"pay_status_desc\": \"已付款\",\n     \"bag\": {\n       \"id\": \"24\",\n       \"name\": \"福袋\",\n       \"type\": \"1\",\n       \"price\": \"100\",\n       \"img\": \"https://admin.bb.uninote.com.cn/oss?path=bb/images/mh03.jpg\",\n       \"sale_type\": \"1\",\n       \"status\": \"3\",\n       \"desc\": \"福袋描述\",\n       \"status_desc\": \"完成\"\n     },\n     \"__boxes\": \"k\",\n     \"boxes\": [\n       {\n         \"id\": \"24\",\n         \"name\": \"血色茉莉\",\n         \"price\": 100,\n         \"img\": \"https://admin.bb.uninote.com.cn/oss?path=bb/images/mh03.jpg\",\n         \"condition\": 1,\n         \"conch_value\": 0\n       }\n     ]\n   }\n }\n}\n",
  },
  'cancel': {
    '__proto__': base,
    'name': 'cancel',
    'desc': " 取消订单",
    'url': '/api/blindbox/order/cancel',
    'args': {
      order_no: {
        'pos': 0,
        'desc': "订单编号(子订单编号)",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"code\": 1,\n \"message\": \"success!\",\n \"data\": {\n   \"info\": \"ok\"\n }\n}\n",
  },
  'subBagChoose': {
    '__proto__': base,
    'name': 'subBagChoose',
    'desc': "二级福袋选择位置\n",
    'url': '/api/blindbox/order/sub-bag-choose',
    'args': {
      bag_id: {
        'pos': 0,
        'desc': "",
        'demo': '',
      },
      sub_bag_id: {
        'pos': 1,
        'desc': "",
        'demo': '',
      },
      positions: {
        'pos': 2,
        'desc': "如果为空，则自动的将所有资格随机选择完",
        'demo': '',
      },

    },
    'returns': "{Promise<*>} {\n \"code\": 1,\n \"message\": \"success!\",\n \"data\": {\n   \"info\": {\n     \"__already_chosen\": \"k\",\n     \"already_chosen\": [\n       1\n     ],\n     suc_count: 1,\n   }\n }\n}\n",
  },
  'pushChooseJob': {
    '__proto__': base,
    'name': 'pushChooseJob',
    'desc': "加入自动选择队列，超级福地部分退款完成后调用\n",
    'url': '/api/blindbox/order/push-choose-job',
    'args': {
      bag_id: {
        'pos': 0,
        'desc': "",
        'demo': '',
      },

    },
    'returns': "{Promise<*>} {\n \"code\": 1,\n \"message\": \"success!\",\n \"data\": {\n   \"info\": {\n     \"push_result\": \"13\"\n   }\n }\n}\n",
  }
};
