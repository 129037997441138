import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../consts';
import {infos as base} from '../../generated/client/index';
import * as apis from '../../../api/client/index';

export const infos = {

  'wxTel': {
    '__proto__': base.wxTel,
    'api': apis.wxTel,
    'desc': "微信登录绑定手机号",
    'url': '/api/user/wx-bind-mobile',
    'defaults': {
      openid: '',
      session_key: '',
      encrypted_data: '',
      iv: '',
      invite_code: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'wxLogin': {
    '__proto__': base.wxLogin,
    'api': apis.wxLogin,
    'desc': "微信小程序登录",
    'url': '/api/user/wx-login',
    'defaults': {
      code: '',
      nick_name: '',
      avatar_url: '',
      signature: '',
      encrypted_data: '',
      iv: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'rotationPicture': {
    '__proto__': base.rotationPicture,
    'api': apis.rotationPicture,
    'desc': "轮播图数据接口",
    'url': '/api/banner/model',
    'defaults': {
      modelID: '',
      adCode: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'getAddressList': {
    '__proto__': base.getAddressList,
    'api': apis.getAddressList,
    'desc': "获取用户地址",
    'url': '/api/address/get',
    'defaults': {
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'sefDefaultAddress': {
    '__proto__': base.sefDefaultAddress,
    'api': apis.sefDefaultAddress,
    'desc': "设置默认用户地址",
    'url': '/api/address/setdefault',
    'defaults': {
      addressId: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'getUserInfo': {
    '__proto__': base.getUserInfo,
    'api': apis.getUserInfo,
    'desc': "获取用户信息",
    'url': '/api/user/info',
    'defaults': {
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'getDefaultAddress': {
    '__proto__': base.getDefaultAddress,
    'api': apis.getDefaultAddress,
    'desc': "获取用户默认地址",
    'url': '/api/address/default',
    'defaults': {
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'codeInvitation': {
    '__proto__': base.codeInvitation,
    'api': apis.codeInvitation,
    'desc': "获取分享邀请码",
    'url': '/api/user/invite',
    'defaults': {
      phone: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'wxpay': {
    '__proto__': base.wxpay,
    'api': apis.wxpay,
    'desc': "微信付款",
    'url': '/api/pay/wx',
    'defaults': {
      orderID: '',
      type: '',
      wx_code: '',
      wx_return_url: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'addAddress': {
    '__proto__': base.addAddress,
    'api': apis.addAddress,
    'desc': "添加新服务地址接口",
    'url': '/api/address/set',
    'defaults': {
      name: '',
      adCode: '',
      desc: '',
      doorplate: '',
      tel: '',
      province: '',
      city: '',
      area: '',
      lng: '',
      lat: '',
      is_default: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'updateUserData': {
    '__proto__': base.updateUserData,
    'api': apis.updateUserData,
    'desc': "设置页面提交用户修改完成的数据",
    'url': '/api/user/updateinfo',
    'defaults': {
      birthday: '',
      nickname: '',
      phone: '',
      sex: '',
      pic: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'updateUserImg': {
    '__proto__': base.updateUserImg,
    'api': apis.updateUserImg,
    'desc': "",
    'url': '/api/upload/image',
    'defaults': {
      file_base64: '',
      scene: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'uploadUrlImg': {
    '__proto__': base.uploadUrlImg,
    'api': apis.uploadUrlImg,
    'desc': "图片上传，通过 url\n",
    'url': '/api/upload/url-image',
    'defaults': {
      url: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'deleteAddress': {
    '__proto__': base.deleteAddress,
    'api': apis.deleteAddress,
    'desc': "用户删除地址",
    'url': '/api/address/del',
    'defaults': {
      id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'editAddress': {
    '__proto__': base.editAddress,
    'api': apis.editAddress,
    'desc': "修改用户地址",
    'url': '/api/address/edit',
    'defaults': {
      id: '',
      name: '',
      adCode: '',
      desc: '',
      doorplate: '',
      tel: '',
      province: '',
      city: '',
      area: '',
      lng: '',
      lat: '',
      is_default: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'loginVerify': {
    '__proto__': base.loginVerify,
    'api': apis.loginVerify,
    'desc': "不知道哪冒出来的方法接口 @fixme",
    'url': 'undefined',
    'defaults': {
      parma: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'telForCode': {
    '__proto__': base.telForCode,
    'api': apis.telForCode,
    'desc': "发送验证码",
    'url': '/api/code/get',
    'defaults': {
      phone: '',
      scenariors: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'telByCodeGetToken': {
    '__proto__': base.telByCodeGetToken,
    'api': apis.telByCodeGetToken,
    'desc': "用户登录 - 验证码登录",
    'url': '/api/user/loginbycode',
    'defaults': {
      phone: '',
      verifyCode: '',
      invite_code: '',
      cid: '',
      cityCode: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'testNotifyWX': {
    '__proto__': base.testNotifyWX,
    'api': apis.testNotifyWX,
    'desc': "微信支付回调（仅用于测试）",
    'url': 'undefined',
    'defaults': {
      orderID: '',
      wx_no: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'home_config': {
    '__proto__': base.home_config,
    'api': apis.home_config,
    'desc': "获取 App 配置",
    'url': '/api/home/config',
    'defaults': {
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'user_invite_group_info': {
    '__proto__': base.user_invite_group_info,
    'api': apis.user_invite_group_info,
    'desc': "获取邀请人数页面聚合数据",
    'url': '/api/user/invite-group-info',
    'defaults': {
      phone: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'user_add_inviter': {
    '__proto__': base.user_add_inviter,
    'api': apis.user_add_inviter,
    'desc': "添加我的邀请者",
    'url': '/api/user/add-inviter',
    'defaults': {
      phone: '',
      invite_code: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'user_get_me_invited': {
    '__proto__': base.user_get_me_invited,
    'api': apis.user_get_me_invited,
    'desc': "获取我邀请的好友列表",
    'url': '/api/user/get-me-invited',
    'defaults': {
      phone: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'user_invite_friend': {
    '__proto__': base.user_invite_friend,
    'api': apis.user_invite_friend,
    'desc': "点击邀请好友聚合接口",
    'url': '/api/user/invite-friend',
    'defaults': {
      phone: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'user_invite': {
    '__proto__': base.user_invite,
    'api': apis.user_invite,
    'desc': "获取邀请码",
    'url': '/api/user/invite',
    'defaults': {
      phone: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'userCloseAccount': {
    '__proto__': base.userCloseAccount,
    'api': apis.userCloseAccount,
    'desc': "注销账户",
    'url': '/api/user/close-account',
    'defaults': {
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'getWxCode': {
    '__proto__': base.getWxCode,
    'api': apis.getWxCode,
    'desc': "获取微信公众号网页授权",
    'url': '/api/user/get-wx-code',
    'defaults': {
      wx_return_url: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'getWxJsapiConfig': {
    '__proto__': base.getWxJsapiConfig,
    'api': apis.getWxJsapiConfig,
    'desc': "获取微信 JS-SDK 配置信息\n",
    'url': '/api/user/get-wx-jsapi-config',
    'defaults': {
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'visit': {
    '__proto__': base.visit,
    'api': apis.visit,
    'desc': "user visit report\n",
    'url': '/api/user/visit',
    'defaults': {
      visit_id: '',
      source_id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  }
};
