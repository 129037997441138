import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from "./consts";
import * as utils from '../utils/utils';
import * as _ from 'lodash';
import {getReqIndex} from '../utils/request';

/*  returns 验证选项 */
// 完整验证
const FULL = 1;
// 不验证 code
const NO_CODE = 2;

export const base = {
  setUserArgs(args) {
    this.user = args;
  },

  // simple overload version
  async request2(noDef = false) {
    return this.request(null, null, false, true, noDef);
  },

  async request(gene, validate, validateReturns = false, ignoreUser = false, noDef = false) {
    let computed = [];
    let typeError = false; // 参数的实际类型与期望类型不匹配
    let key, defType, resolvedType;
    // resolve 每个参数，并校验类型
    for (key of Object.keys(this.args)) {
      let defArg = this.defaults[key];
      let userArg = ignoreUser ? "" : this.user[key];
      let geneArg = this.generated[key];
      let geneType = "undefined";
      let resolvedArg = noDef ? undefined : defArg;
      if (userArg || (userArg !== "" && userArg !== undefined)) {
        resolvedArg = userArg;
      } else if (geneArg !== undefined) {
        resolvedArg = geneArg;
        geneType = utils.getType(geneArg);
      }
      resolvedType = utils.getType(resolvedArg);
      defType = utils.getType(defArg);
      try {
        // 期望类型为 string 以及用户无输入时不转换，
        if ((geneType !== "string" && geneType !== "undefined" || geneType === "undefined" && defType !== "string") && resolvedArg) {
          if (resolvedType === "string") {
            resolvedArg = utils.looseJsonParse(resolvedArg);
          }
          resolvedType = utils.getType(resolvedArg); // 可能的类型：number/object/array
          if (geneType !== resolvedType && defType !== resolvedType) {
            typeError = true;
            break;
          }
        }
      }
      catch (e) {
        console.log(e);
        utils.showError('JSON 解析失败，字符串: ' + resolvedArg);
        return {data: ""};
      }

      computed[this.args[key].pos] = resolvedArg;
    }
    if (typeError) {
      utils.showError(`类型不匹配。参数名：${key}, 期望类型：${defType}， 实际类型：${resolvedType}`);
      return {data: ""};
    }
    // "before" request callbacks
    if (gene && gene.before) {
      await gene.before(this);
    } else if (this.before) {
      await this.before(this);
    }
    let p = this.api.apply(null, computed);
    let index = getReqIndex();
    this.reqIndex = index;
    if (utils.getParam('debug')) {
      console.log(index, this.url, computed);
    }
    let info = await p;
    // "after" request callbacks
    if (gene && gene.after) {
      await gene.after(this, info);
    } else if (this.after) {
      await this.after(this, info);
    }
    let returns = "";
    try {
      if (validate) {
        // validate's this 绑定为 cur gene. 如果需要，gene 不能用箭头函数
        await validate.call(gene, this, info);
      }
      if (validateReturns) {
        // 验证注释中记录的 returns
        returns = this.returns.replace(/{Promise<.*>}/, '').trim();
        if (returns) {
          let options = {
            keyList: this.keyList,
            nullList: this.nullList,
            typeList: this.typeList,
            ignoreList: this.ignoreList,
            noCode: validateReturns === NO_CODE,
          };
          returns = utils.looseJsonParse(returns); // 这里转为对象，便于后面异常时输出对象格式
          utils.assertSuc(info, returns, 'returns 验证失败', options);
        }
      }
    } catch (e) {
      let err = new Error('returns 验证失败, reqIndex: ' + index);
      err.api = _.cloneDeep(this); // 复制，因为响应式数据不便于查看
      err.origin = e;
      err.info = {
        current: info,
        sample: returns,
      };
      throw err;
    }
    // 记录上次接口返回结果，以备后用
    this.lastResult = info;
    if (this.cloneFrom) {
      this.cloneFrom.lastResult = info;
    }
    return info;
  },

  /**
   * generate args and make request with gene function's validation
   * @param scene 指定 参数的生成函数(与 geneArgs.scene 匹配)。默认为 'default'
   * @param funcArgs Array 传递给 gene.func 的参数
   * @return {Promise<*>}
   */
  async make(scene = 'default', ...funcArgs) {
    for (let gene of this.geneArgs) {
      if (gene.scene === scene) {
        let clone;
        if (!this.cloneFrom) {
          clone = _.cloneDeep(this);
          clone.cloneFrom = this;
        } else {
          clone = this;
        }
        funcArgs.unshift(clone);
        await gene.func.apply(null, funcArgs);
        if (gene.type !== GENE_ONLY) {
          try {
            return await clone.request(gene, gene.validate, gene.noCode ? NO_CODE : FULL, true, gene.noDef);
          } catch (e) {
            e.handled = true;
            e.api =  _.cloneDeep(this); // 复制，因为响应式数据不便于查看
            handleError(e, 'making');
            throw e;
          }
        }
        return;
      }
    }
    throw new Error(`找不到生成函数: ${scene}`);
  },

  /**
   * 单一接口完整验证。
   * @return {Promise<boolean>}
   */
  async fullValidate(stopOnError = false, curGene = false) {
    try {
      let api = this;
      console.log(api.desc + ', ' + api.url);
      for (let gene of api.geneArgs) {
        if (gene.type === NO_BATCH || gene.type === INNER ||
          (curGene && curGene !== gene) // 只验证当前的 gene（如果传入了）
        ) {
          // NO_BATCH 在 当前生成完整验证 时也需要 请求/验证
          if (!(gene.type === NO_BATCH && (curGene && curGene === gene))) {
            continue;
          }
        }
        // 1、常规测试
        console.log('  gene validate: ' + gene.scene);
        if (gene.skip) {
          let skip = gene.skip();
          if (skip) {
            continue;
          }
        }
        await gene.func(api);
        if (gene.type !== GENE_ONLY) {
          await api.request(gene, gene.validate, gene.noCode ? NO_CODE : FULL, true, gene.noDef);
        }

        // save args
        let savedArgs = _.cloneDeep(api.generated);

        // 2、unit tests
        if (gene.testSuites) {
          async function oneTest(suite, privateValidate) {
            let info = await api.request(gene, null, false, true, gene.noDef);
            // 三级 validate，按优先级执行其中一个（如果定义了）
            if (privateValidate) {
              await privateValidate(api, info);
            } else if (suite.validate) {
              await suite.validate(api, info);
            } else if(gene.validate) {
              await gene.validate(api, info);
            }
          }

          for (let suite of gene.testSuites) {
            console.log("  test suite: " + suite.name);

            // for each test cases
            for (let i in suite.tests) {
              console.log("    test case i: " + i);
              let test = suite.tests[i];
              let privateValidate;
              // restore before changes in test cases
              api.generated = _.cloneDeep(savedArgs);
              if (typeof(test) === "function") {
                privateValidate = await test(api);
                if (privateValidate !== false) {
                  await oneTest(suite, privateValidate);
                }
              } else {
                // 此 test 为一个不能为空的 key
                api.generated[test] = undefined;
                await oneTest(suite);
                api.generated[test] = '';
                await oneTest(suite);
              }
            }

            if (suite.testPairs) {
              // for each test cases(pair)
              for (let i = 0; i < suite.testPairs.length; i += 2) {
                console.log("    test case(pair) i: " + i);
                // restore before changes in test cases
                api.generated = _.cloneDeep(savedArgs);
                api.generated[suite.testPairs[i]] = suite.testPairs[i + 1];
                await oneTest(suite);
              }
            }
          }
        }
      }
      return true;
    } catch (e) {
      if (!e.handled) {
        if (!e.api) {
          // 不是在 request 中的异常，api 不会设置
          e.api =  _.cloneDeep(this); // 复制，因为响应式数据不便于查看
        }
        handleError(e, 'full validating');
      }
      if (stopOnError)
        throw e;
      return false;
    }
  },

  /**
   * save this.generated args to this.__savedArgs
   */
  save() {
    this.__savedArgs = _.cloneDeep(this.generated);
  },

  /**
   * restore this.__savedArgs to this.generated
   */
  restore() {
    this.generated = _.cloneDeep(this.__savedArgs);
  },
};

function handleError(e, type) {
  console.group(`error occurs while ${type} api: `, e.api);
  if (e.isAxiosError) {
    console.error("Axios Error", e, e.config);
  } else {
    console.error(e);
  }
  if (e.origin) {
    console.error('origin: ', e.origin);
  }
  if (e.info) {
    console.error('info: ', e.info);
  }
  console.groupEnd();
}
