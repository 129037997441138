import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../../consts';
import {infos as base} from '../../../generated/scms/blindbox/bag';
import * as apis from '../../../../api/scms/blindbox/bag';
import * as utils from '@/utils/utils'
import * as client_index from '@/powder/user/client/index';
import * as constants from '@/config/constant';
import * as bb_orders from '@/powder/user/client/blindbox/order';
import {infos as client_job} from '@/powder/user/client/blindbox/job';
import { assertOK, assertParamEmpty } from '@/bb/assert';
import * as bb_utils from '@/bb/util';

export const infos = {

  'create': {
    '__proto__': base.create,
    'api': apis.create,
    'desc': "创建常规福袋\n",
    'url': '/scmsapi/blindbox/bag/create',
    'defaults': {
      type: 1,
      name: '',
      price: 1,
      start_time: '',
      end_time: '',
      sale_type: 1,
      for_sale: 1,
      desc: '',
      img: '',
      boxes: [],
      rank_boxes: [],
      sum_boxes: [],
      sub_bags: [],
      prob: 10,
      hidden: 0,
      allow_conch: 1,
      last_count: 0,
      limit_count: 0,
      profit: {max_sale: 0, exp_sale: 0, all_conch: 0},
    },
    'generated': {},
    'user': {},
    'validateStatus': async (id, status) => {
      let res = await infos.info.make('args', id);
      utils.assert(res.data.data.status === status,
        '预期状态不符:' + res.data.data.status + ', expected: ' + status);
    },
    'geneCommon': async function(ctx, start_time, end_time, once_more, partial = false) {
      const type = constants.BagType;
      ctx.generated = {
        type,
        name: '福袋',
        price: 202,
        start_time,
        end_time,
        sale_type: 1,
        desc: '福袋描述',
        img: 'https://admin.bb.uninote.com.cn/oss?path=uploads/images/2023/07/5de7a8902f451e1c61984a1d5c6546fa.jpeg',
        boxes: [
          {
          name: '血色玫瑰',
          price: 120,
          total: 2,
          img: 'https://admin.bb.uninote.com.cn/oss?path=bb/images/mh03.jpg',
          conch_value: 90,
          condition: 1,
        },
        {
          name: '血色茉莉',
          price: 100,
          total: 1,
          img: 'https://admin.bb.uninote.com.cn/oss?path=img/WechatIMG1047041.jpeg',
          conch_value: 0,
          condition: 1,
        }],
        rank_boxes: [{
          name: '彩色糕点',
          price: 100,
          rank: 2,
          img: 'https://admin.bb.uninote.com.cn/oss?path=uploads/images/2023/07/a7e8dda79adf00b71b1e74dfd5a8b0e2.jpeg',
          conch_value: 0,
          condition: 1,
        }],
      };
      ctx.generated.prob = once_more && !partial ? 100 : 10;
      if (type === constants.BAG_TYPE_PLUS) {
        ctx.generated.sum_boxes = [];
        for (let i = 0; i < 28; i++) {
          ctx.generated.sum_boxes.push({
            name: '彩色糕点' + i,
            price: 100,
            sum: i,
            once_more: (once_more && !partial || once_more && partial && i < 10) ? "1" : "0",
            img: 'https://admin.bb.uninote.com.cn/oss?path=uploads/images/2023/07/a7e8dda79adf00b71b1e74dfd5a8b0e2.jpeg',
            conch_value: 0,
            condition: 1,
          });
        }
      }
      if (type === constants.BAG_TYPE_SUPER1) {
        ctx.generated.boxes = [
          {
            name: '超级-常规盲盒',
            price: 100,
            total: 1,
            img: 'https://admin.bb.uninote.com.cn/oss?path=bb_ty/2023-12/2f6dcb3a41103c5fecee5f98a15b0179.jpeg',
            conch_value: 10,
            condition: 1,
          }];
        ctx.generated.sub_bags = [
          {
            name: "二级福袋A",
            img: 'https://admin.bb.uninote.com.cn/oss?path=uploads/images/2023/07/5de7a8902f451e1c61984a1d5c6546fa.jpeg',
            boxes: [
              {
                name: "21",
                price: 120,
                total: 2,
                img: "https://admin.bb.uninote.com.cn/oss?path=bb_ty/2023-12/dc9be7cc79136f9a886f29639c616785.jpeg",
                conch_value: 20,
                condition: 1
              },
              // {
              //   name: "22",
              //   price: 100,
              //   total: 8,
              //   img: "https://admin.bb.uninote.com.cn/oss?path=bb_ty/2023-12/f831932566bf5602c548805028106baa.jpeg",
              //   conch_value: 0,
              //   condition: 1
              // }
            ]
          },
          {
            name: "二级福袋B",
            img: 'https://admin.bb.uninote.com.cn/oss?path=uploads/images/2023/07/5de7a8902f451e1c61984a1d5c6546fa.jpeg',
            boxes: [
              {
                name: "23",
                price: 120,
                total: 2,
                img: "https://admin.bb.uninote.com.cn/oss?path=bb_ty/2023-12/7bf930b0f06bd6b4662d1743fd659654.jpeg",
                conch_value: 30,
                condition: 1
              },
              // {
              //   name: "24",
              //   price: 100,
              //   total: 7,
              //   img: "https://admin.bb.uninote.com.cn/oss?path=bb_ty/2023-12/abfdbe01002f09865e50f7ee5334bb31.jpeg",
              //   conch_value: 0,
              //   condition: 1
              // }
            ]
          }
        ]
      }
    },
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '售卖中',
        'type': NORMAL,
        'func': async (ctx) => {
          const start_time = parseInt(Date.now()/1000);
          await ctx.geneCommon(ctx, start_time - 3600, start_time + 7200);
        },
        'validate': async (ctx, info) => {
          await ctx.validateStatus(info.data.data.id, 2);
        },
      },
      {
        'scene': 'args-validate',
        'desc': '参数验证',
        'type': GENE_ONLY,
        'noDef': true,
        'func': async (ctx) => {
          const start_time = parseInt(Date.now()/1000);
          await ctx.geneCommon(ctx, start_time - 3600, start_time + 7200);
        },
        'testSuites': [
          {
            'name': 'fail',
            'validate': assertParamEmpty,
            'tests': ['type', 'name', 'price', 'start_time', 'end_time', 'sale_type'],
            'testPairs': [
              'type', 10,
              'name', '012345678901234567890123456789012345678901234567891',
              'price', '3.3',
              'price', '-2',
              'start_time', -1,
              'sale_type', 10,
              'for_sale', 10,
              'prob', -1,
              'prob', 101,
              'hidden', 10,
              'allow_conch', 10,
              'last_count', -1,
              'limit_count', -1,
            ],
          },
        ]
      },
      {
        'scene': 'to-sale',
        'desc': '即将开卖',
        'type': NORMAL,
        'func': async (ctx) => {
          const start_time = parseInt(Date.now()/1000);
          // TODO: 当服务器不在本地时，可能得不到预期的状态
          ctx.geneCommon(ctx, start_time + 2, start_time + 3600);
        },
        'validate': async (ctx, info) => {
          await ctx.validateStatus(info.data.data.id, constants.BAG_STATUS_NEW);
          await utils.sleep(2500);
          await client_job.updateBagStatus.make();
          await ctx.validateStatus(info.data.data.id, constants.BAG_STATUS_IN_SALE);
        },
      },
      {
        'scene': 'on-sale-2-abort',
        'desc': '满开-到时-终止',
        'type': NORMAL,
        skip: () => constants.BagType === constants.BAG_TYPE_SUPER1 || constants.BagType === constants.BAG_TYPE_PLUS,
        'func': async (ctx) => {
          const start_time = parseInt(Date.now()/1000);
          // TODO: 当服务器不在本地时，可能得不到预期的状态
          ctx.geneCommon(ctx, start_time - 1000, start_time + 2);
        },
        'validate': async (ctx, info) => {
          await ctx.validateStatus(info.data.data.id, constants.BAG_STATUS_IN_SALE);
          // 等待超时
          await utils.sleep(2500);
          await infos.abortAllExpired.make();
          let suc = false;
          let res;
          // 等待退款 job 完成
          for (let i = 0; i < 10; i++) {
            await utils.sleep(1000);
            res = await infos.info.make('args', info.data.data.id);
            if (res.data.data.status === constants.BAG_STATUS_ABORT) {
              suc = true;
              break;
            }
          }
          utils.assert(suc,
            '预期状态不符:' + res.data.data.status + ', expected: ' + constants.BAG_STATUS_ABORT);
        },
      },
      {
        'scene': 'args',
        'type': INNER,
        func: async (ctx, args) => {
          const start_time = parseInt(Date.now()/1000);
          ctx.geneCommon(ctx, start_time + 60, start_time + 3600);
          for (let key in args) {
            ctx.generated[key] = args[key];
          }
        }
      },
      {
        'scene': 'instant-on-sale',
        'desc': '即买即开-售卖中',
        'type': NORMAL,
        'func': async (ctx, onceMore) => {
          const start_time = parseInt(Date.now()/1000);
          await ctx.geneCommon(ctx, start_time - 3600, start_time + 7200, onceMore);
          ctx.generated.sale_type = 2;
        },
      },
      {
        'scene': 'once-more',
        'desc': '再来一次（100%中奖）',
        'type': NO_BATCH,
        'skip': () => constants.BagType === constants.BAG_TYPE_NORMAL,
        'func': async (ctx) => {
          const start_time = parseInt(Date.now()/1000);
          await ctx.geneCommon(ctx, start_time - 3600, start_time + 7200, true);
        },
      },
      {
        'scene': 'once-more-partial',
        'desc': '再来一次，加加乐仅和值为0-9的部分盲盒(22%)，刮刮乐10%',
        'type': NO_BATCH,
        'skip': () => constants.BagType === constants.BAG_TYPE_NORMAL,
        'func': async (ctx) => {
          const start_time = parseInt(Date.now()/1000);
          await ctx.geneCommon(ctx, start_time - 3600, start_time + 7200, true, true);
        },
      }
    ],
  },

  'update': {
    '__proto__': base.update,
    'api': apis.update,
    'desc': "更新常规福袋\n",
    'url': '/scmsapi/blindbox/bag/update',
    'defaults': {
      id: 1,
      name: '',
      price: 1,
      start_time: '',
      end_time: '',
      sale_type: 1,
      for_sale: 1,
      desc: '',
      img: '',
      boxes: [],
      rank_boxes: [],
      sum_boxes: [],
      sub_bags: [],
      prob: 20,
      hidden: 0,
      allow_conch: 1,
      last_count: 0,
      limit_count: 0,
      profit: {max_sale: 0, exp_sale: 0, all_conch: 0},
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NO_BATCH,
        'func': async (ctx) => {
          let res = await infos.list.make('args', 1);
          let id = res.data.data.data[0].id;
          res = await infos.info.make('args', id);
          ctx.generated = res.data.data;
          ctx.generated.name = "盲盒" + utils.randomContent();
        }
      },
      {
        'scene': 'create',
        'type': NORMAL,
        func: async (ctx) => {
          const start_time = parseInt(Date.now()/1000) + 3600;
          let res = await infos.create.make('args', {start_time});
          res = await infos.info.make('args', res.data.data.id);
          ctx.generated = res.data.data;
          ctx.generated.name = "updated_name";
          ctx.generated.prob = 15.5;
          ctx.generated.start_time = start_time - 7200; // 改为过去的时间，状态变为售卖中
        },
        validate: async (ctx, info) => {
          let res = await infos.info.make('args', ctx.generated.id);
          utils.assert(res.data.data.status === 2, "状态没有变为售卖中");
          utils.assert(res.data.data.prob === 15.5, "概率修改失败");
        },
        'testSuites': [
          {
            'name': 'suc',
            'tests': [
              (ctx) => {
                ctx.generated.name = 'changed_name';
                return async (ctx, info) => {
                  let res = await infos.info.make('args', ctx.generated.id);
                  utils.assert(res.data.data.name === 'changed_name', "售卖中状态修改名字失败");
                }
              },
            ],
          },
          {
            'name': 'fail for status',
            'tests': [
              (ctx) => {
                ctx.generated.prob = 25;
                return async (ctx, info) => {
                  let res = await infos.info.make('args', ctx.generated.id);
                  utils.assert(res.data.data.prob !== 25, "售卖中状态不能修改概率");
                }
              },
            ]
          },
          {
            'name': 'suc for partial update',
            'validate': async (ctx, info) => {
              assertOK(ctx, info);
            },
            'tests': ['start_time', 'sale_type', 'price', 'boxes', 'rank_boxes', 'sum_boxes'],
          },
        ]
      },
    ],
  },

  'updateForSale': {
    '__proto__': base.updateForSale,
    'api': apis.updateForSale,
    'desc': "福袋上下架\n",
    'url': '/scmsapi/blindbox/bag/updateForSale',
    'defaults': {
      id: 0,
      for_sale: 0,
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          let res = await infos.create.make();
          ctx.generated = {
            id: res.data.data.id,
            for_sale: 0,
          };
        },
        validate: async (ctx, info) => {
          let res = await infos.info.make('args', infos.create.lastResult.data.data.id);
          utils.assert(res.data.data.for_sale === 0, "下架失败");
        },
      },
      {
        'scene': 'args',
        'desc': '',
        'type': INNER,
        'func': async (ctx, id, for_sale) => {
          ctx.generated = {
            id,
            for_sale,
          };
        },
      },
    ],
  },

  'list': {
    '__proto__': base.list,
    'api': apis.list,
    'desc': "常规福袋列表\n",
    'url': '/scmsapi/blindbox/bag/list',
    'defaults': {
      id: 0,
      name: '',
      status: '',
      type: '',
      sale_type: '',
      for_sale: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            type: constants.BagType,
          };
        }
      },
      {
        'scene': 'args',
        'desc': '',
        'type': INNER,
        'func': async (ctx, status) => {
          ctx.generated = {
            type: constants.BagType,
            status,
          };
        }
      },
    ],
  },

  'info': {
    '__proto__': base.info,
    'api': apis.info,
    'desc': "获取福袋信息\n",
    'url': '/scmsapi/blindbox/bag/info',
    'defaults': {
      id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          let data = await infos.list.make();
          ctx.generated = {
            id: data.data.data.data[0].id,
          };
        }
      },
      {
        'scene': 'create',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          let data = await infos.create.make();
          ctx.generated = {
            id: data.data.data.id,
          };
        }
      },
      {
        'scene': 'args',
        'desc': '',
        'type': INNER,
        'func': async (ctx, id) => {
          ctx.generated = {
            id,
          };
        }
      },
    ],
  },

  'abort': {
    '__proto__': base.abort,
    'api': apis.abort,
    'desc': "终止福袋\n",
    'url': '/scmsapi/blindbox/bag/abort',
    'defaults': {
      id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx, id) => {
          if (!id) {
            await client_index.infos.testNotifyWX.make('blindbox');
            id = infos.create.lastResult.data.data.id;
          }
          ctx.generated = {
            id,
          };
        }
      },
      {
        'scene': 'once-more',
        'desc': '',
        'type': NORMAL,
        'skip': () => constants.BagType === constants.BAG_TYPE_NORMAL,
        'func': async (ctx) => {
          await bb_orders.infos.create.make('once-more');
          ctx.generated = {
            id: infos.create.lastResult.data.data.id,
          };
        }
      },
      {
        'scene': 'once-more-即买即开',
        'desc': '',
        'type': NORMAL,
        'skip': () => constants.BagType === constants.BAG_TYPE_NORMAL,
        'func': async (ctx) => {
          await bb_orders.infos.create.make('once-more', 'instant-on-sale');
          ctx.generated = {
            id: infos.create.lastResult.data.data.id,
          };
        }
      },
      {
        'scene': 'once-more-use-满开',
        'desc': '',
        'type': NORMAL,
        'skip': () => constants.BagType === constants.BAG_TYPE_NORMAL,
        'func': async (ctx) => {
          await bb_orders.infos.create.make('once-more-use');
          ctx.generated = {
            id: infos.create.lastResult.data.data.id,
          };
        }
      },
      {
        'scene': 'once-more-use-即买即开',
        'desc': '',
        'type': NORMAL,
        'skip': () => constants.BagType === constants.BAG_TYPE_NORMAL,
        'func': async (ctx) => {
          await bb_orders.infos.create.make('once-more-use', 'instant-on-sale');
          ctx.generated = {
            id: infos.create.lastResult.data.data.id,
          };
        }
      },
      {
        'scene': 'conch',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          await client_index.infos.testNotifyWX.make('blindbox-conch-all');
          let id = infos.create.lastResult.data.data.id;
          ctx.generated = {
            id,
          };
        }
      },
      {
        'scene': 'conch-invalid-off-sale',
        'desc': '终止后对贝壳无影响',
        'type': NORMAL,
        'func': async (ctx) => {
          await client_index.infos.testNotifyWX.make('blindbox-conch-invalid-off-sale');
          let id = infos.create.lastResult.data.data.id;
          ctx.generated = {
            id,
          };
        }
      },
      {
        'scene': 'args',
        'desc': '',
        'type': INNER,
        'func': async (ctx, id) => {
          ctx.generated = {
            id,
          };
        }
      },
      {
        'scene': 'super-partial',
        'desc': '超级福袋已经卖完一轮 + 一个位置后终止',
        'type': NORMAL,
        'skip': () => constants.BagType !== constants.BAG_TYPE_SUPER1,
        'func': async (ctx) => {
          await bb_orders.infos.pay.make('pay-all-partial');
          ctx.generated = {
            id: infos.create.lastResult.data.data.id,
          };
        }
      },
    ],
  },

  'abortAllExpired': {
    '__proto__': base.abortAllExpired,
    'api': apis.abortAllExpired,
    'desc': "终止所有过期的福袋\n",
    'url': '/scmsapi/blindbox/bag/abortAllExpired',
    'defaults': {
      all: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
      {
        'scene': 'once-more-refund-0',
        'desc': 'once-more-refund-0分',
        'type': NORMAL,
        'skip': () => constants.BagType === constants.BAG_TYPE_NORMAL,
        'func': async (ctx) => {
          await bb_orders.infos.pay.make('pay-all-once-more');
          ctx.generated = {};
        },
        validate: (ctx, info) => {
          utils.assert(info.data.data.onceMoreOrderIDs.length > 0, 'once-more-refund-0分 订单ID数组为空');
        }
      },
      {
        'scene': 'once-more-refund',
        'desc': 'once-more 完成后退款',
        'type': NORMAL,
        'skip': () => constants.BagType === constants.BAG_TYPE_NORMAL,
        'func': async (ctx) => {
          await bb_orders.infos.create.make('once-more-no-use');
          let bag_id = await infos.create.lastResult.data.data.id;

          // way1: 终止福袋的方式，需要等待定时器
          // await infos.abort.make('default', bag_id);

          // way2: 再购买剩余的全部，状态变为完成
          await bb_orders.infos.pay.make('pay-all-get', bag_id);

          // 超级福袋等待完成
          await bb_utils.waitSuperBagToFinished(bag_id);
        },
        validate: (ctx, info) => {
          utils.assert(info.data.data.onceMoreOrderIDs.length > 0, 'once more 完成后退款 订单ID数组为空');
        }
      },
    ],
  },

  'getStatDataUser': {
    '__proto__': base.getStatDataUser,
    'api': apis.getStatDataUser,
    'desc': "获取福袋按用户分组统计信息\n",
    'url': '/scmsapi/blindbox/bag/getStatDataUser',
    'defaults': {
      ids: '',
      combined: false,
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NO_BATCH,
        'func': async (ctx) => {
          let res = await infos.list.make();
          ctx.generated = {
            ids: res.data.data.data[0].id,
          };
        }
      },
      {
        'scene': 'args',
        'desc': '',
        'type': INNER,
        'func': async (ctx, ids) => {
          ctx.generated = {
            ids,
          };
        }
      },
      {
        'scene': 'create',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          await client_index.infos.testNotifyWX.make('blindbox-instant');
          ctx.generated = {
            ids: infos.create.lastResult.data.data.id,
          };
        }
      },
      {
        'scene': 'create-once-more',
        'desc': '',
        'type': NORMAL,
        'skip': () => constants.BagType === constants.BAG_TYPE_NORMAL,
        'func': async (ctx) => {
          await bb_orders.infos.create.make('once-more');
          ctx.generated = {
            ids: infos.create.lastResult.data.data.id,
          };
        }
      },
    ],
  },

  'updateStat': {
    '__proto__': base.updateStat,
    'api': apis.updateStat,
    'desc': "更新福袋统计数据。不传参数则是全量更新\n",
    'url': '/scmsapi/blindbox/bag/updateStat',
    'defaults': {
      ids: [],
      back_days: 0,
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NO_BATCH,
        'func': async (ctx) => {
          let res = await infos.list.make();
          ctx.generated = {
            ids: [res.data.data.data[0].id],
          };
        }
      },
      {
        'scene': 'create',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          await client_index.infos.testNotifyWX.make('blindbox-instant');
          ctx.generated = {
            ids: [infos.create.lastResult.data.data.id],
          };
        }
      },
      {
        'scene': 'create-once-more',
        'desc': '',
        'type': NORMAL,
        'skip': () => constants.BagType === constants.BAG_TYPE_NORMAL,
        'func': async (ctx) => {
          await bb_orders.infos.create.make('once-more');
          ctx.generated = {
            ids: [infos.create.lastResult.data.data.id],
          };
        }
      },
    ],
  }
};
