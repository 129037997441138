import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../../consts';
import {infos as base} from '../../../generated/client/blindbox/order';
import * as apis from '../../../../api/client/blindbox/order';
import {infos as bag} from './bag.js';
import {infos as bbAdmin } from '@/powder/user/scms/blindbox/bag';
import {infos as client } from '@/powder/user/client/index';
import {infos as job } from '@/powder/user/client/blindbox/job';
import * as assert from '@/bb/assert';
import * as utils from '@/utils/utils';
import * as constants from '@/config/constant';
import * as bb_utils from '@/bb/util';

export const infos = {

  'create': {
    '__proto__': base.create,
    'api': apis.create,
    'desc': "创建福袋订单\n",
    'url': '/api/blindbox/order/create',
    'defaults': {
      bag_id: 0,
      round: 0,
      position: 0,
      conch_first: false,
    },
    'generated': {},
    'user': {},
    'geneCommon': async (ctx, bag_info) => {
      // 在当前轮次，尝试随机找到一个位置购买，如果失败，position === -1
      let posMap = bag_info.orders.map(order => order.round + '-' + order.position);
      let pos = -1;
      const try_pos_start = parseInt(Math.random() * 1000);
      const rInfo = bb_utils.getRoundInfo(bag_info);
      if (rInfo.cur_round < bag_info.total_round) {
        for (let i=0; i<rInfo.count_per_round; i++) {
          const try_pos = (i + try_pos_start) % rInfo.count_per_round;
          if (posMap.indexOf(rInfo.cur_round + '-' + try_pos) === -1) {
            pos = try_pos;
            break;
          }
        }
      }
      ctx.generated = {
        bag_id: bag_info.boxs[0].bag_id,
        position: pos,
        round: rInfo.cur_round,
      };
    },
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          let res = await bag.luckyBagInfo.make('create');
          await ctx.geneCommon(ctx, res.data.data.info);
        }
      }, {
        'scene': 'instant',
        'desc': '即买即开订单',
        'type': NORMAL,
        'func': async (ctx) => {
          let res = await bag.luckyBagInfo.make('create', 'instant-on-sale');
          await ctx.geneCommon(ctx, res.data.data.info);
        }
      }, {
        'scene': 'try-one',
        'desc': '随机尝试购买一个',
        'type': INNER,
        'func': async (ctx, bag_info) => {
          await ctx.geneCommon(ctx, bag_info);
        }
      }, {
        'scene': 'id-pos',
        'desc': '指定 id/pos 参数',
        'type': INNER,
        'func': async (ctx, bag_id, pos, conch_first = false, round = 0) => {
          ctx.generated = {
            bag_id,
            position: pos,
            conch_first,
            round,
          };
        }
      }, {
        'scene': 'get-bag',
        'desc': '尝试从指定 id bag 中购买一个（或者last-bag）',
        'type': NO_BATCH,
        'func': async (ctx, id) => {
          let res = await bag.luckyBagInfo.make('id', id);
          await ctx.geneCommon(ctx, res.data.data.info);
        }
      }, {
        'scene': 'once-more-no-use',
        'desc': '再来一次, 没有使用',
        'type': GENE_ONLY,
        'skip': () => constants.BagType === constants.BAG_TYPE_NORMAL,
        'func': async (ctx) => {
          // create bag
          let bag = await bbAdmin.create.make('once-more');
          let bag_id = bag.data.data.id;
          ctx.__bag_id = bag_id;
          // create order and do wx-pay
          let res = await ctx.make('id-pos', bag_id, 0);
          // 再次创建订单
          let res2 = await ctx.make('id-pos', bag_id, 1);
          // 支付这两单
          await client.testNotifyWX.make('args', res.data.data.info.order_no);
          await client.testNotifyWX.make('args', res2.data.data.info.order_no);
        },
        testSuites: [
          {
            'name': '后续验证',
            'tests': [
              async (ctx) => {
                let res = await bbAdmin.getStatDataUser.make('args', ctx.__bag_id);
                utils.assert(res.data.data[0].once_more_count === 2, "再来一次单数不为2: " + res.data.data[0].once_more_count);
                return false;
              },
            ]
          }
        ],
      }, {
        'scene': 'once-more',
        'desc': '再来一次，生成一个1分钱订单',
        'type': GENE_ONLY,
        'skip': () => constants.BagType === constants.BAG_TYPE_NORMAL,
        // createScene: 'instant-on-sale' | undefined
        'func': async (ctx, createScene) => {
          // create bag, 'instant-on-sale' scene 时，再传入 onceMore = true
          let bag = await bbAdmin.create.make(createScene || 'once-more-partial', true);
          let bag_id = bag.data.data.id;
          ctx.__bag_id = bag_id;
          // create order and do wx-pay，命中再来一次
          let res = await ctx.make('id-pos', bag_id, 0);
          await client.testNotifyWX.make('args', res.data.data.info.order_no, parseInt(Math.random() * 1000000) + '001');
          // 再次创建订单
          res = await ctx.make('id-pos', bag_id, 1);
          // 验证支付金额为 1 分
          let res2 = await infos.info.make('args', res.data.data.info.id);
          utils.assert(res2.data.data.info.amount * 1 === 1,
            "刮刮乐再来一次支付金额不为1分, info: " + JSON.stringify(res2.data.data.info));
        },
        'testSuites': [
          {
            'name': '后续验证',
            'tests': [
              async (ctx) => {
                let res = infos.info.lastResult;
                // 再次支付，不命中
                await client.testNotifyWX.make('args', res.data.data.info.order_no, parseInt(Math.random() * 1000000) + '555');
                // 再次创建订单
                res = await ctx.make('id-pos', ctx.__bag_id, 2);
                // 验证支付金额不为 1 分
                let res2 = await infos.info.make('args', res.data.data.info.id);
                utils.assert(res2.data.data.info.amount * 1 !== 1,
                  "刮刮乐再来一次支付金额不应为1分, info: " + JSON.stringify(res2.data.data.info));
                return false;
              },
            ]
          },
        ],
      }, {
        'scene': 'once-more-cancel-reuse',
        'desc': '再来一次取消订单后重用',
        'type': GENE_ONLY,
        'skip': () => constants.BagType === constants.BAG_TYPE_NORMAL,
        'func': async (ctx) => {
          await infos.cancel.make('once-more');
          let res = await ctx.make('get-bag', bbAdmin.create.lastResult.data.data.id);
          // 验证支付金额为 1 分
          res = await infos.info.make('args', res.data.data.info.id);
          utils.assert(res.data.data.info.amount * 1 === 1,
            "再来一次取消订单后重用 支付金额不为1分, info: " + JSON.stringify(res.data.data.info));
        }
      }, {
        'scene': 'scratch-once-more-no-use', // 暂时不方便与加加乐的合并
        'desc': '刮刮乐再来一次, 没有使用',
        'type': GENE_ONLY,
        'skip': () => constants.BagType !== constants.BAG_TYPE_SCRATCH,
        'func': async (ctx) => {
          async function oneTest(scene, conch_first) {
            // create bag
            let bag = await bbAdmin.create.make(scene);
            let bag_id = bag.data.data.id;
            // create order and do wx-pay
            let res = await ctx.make('id-pos', bag_id, 0, conch_first);
            // 再次创建订单
            let res2 = await ctx.make('id-pos', bag_id, 1);
            // 支付这两单
            await client.testNotifyWX.make('args', res.data.data.info.order_no, parseInt(Math.random() * 1000000) + '001');
            await client.testNotifyWX.make('args', res2.data.data.info.order_no, parseInt(Math.random() * 1000000) + '555');
            // 终止福袋
            await bbAdmin.abort.make('default', bag_id);
          }
          await oneTest('default', false);
          await oneTest('instant-on-sale', false);
          await oneTest('default', true);

          // once-more 未使用贝壳退款测试
          // 等待queue先执行一下，因为可能还有改变 conch 的任务
          // TODO: 查询等待队列为空，不要写死等待时间
          await utils.sleep(4000);
          let res = await client.getUserInfo.make();
          let firstConch = res.data.data.info.conch;
          await oneTest('instant-on-sale', true);
          await job.updateBagStatus.make(); // 触发状态更新
          await bbAdmin.abortAllExpired.make(); // 加入 queue
          await bbAdmin.abortAllExpired.make(); // 加入 queue 多次，测试不会重复生成流水
          await utils.sleep(2000); // 等待queue先执行一下，因为中间某个状态可能贝壳值符合预期
          await assert.assertConch(firstConch, 'once-more 未使用退款没有恢复贝壳');
        }
      }, {
        'scene': 'once-more-use',
        'desc': '再来一次, 使用了',
        'type': GENE_ONLY,
        'skip': () => constants.BagType === constants.BAG_TYPE_NORMAL,
        'func': async (ctx, createScene) => {
          await ctx.make('once-more', createScene);
          await client.testNotifyWX.make('args', ctx.lastResult.data.data.info.order_no);
        }
      }, {
        'scene': 'conch',
        'desc': '贝壳支付',
        'type': NORMAL,
        'func': async (ctx, is_instant) => {
          ctx.__userInfo = await client.getUserInfo.make();
          const price = 150;
          let bag = await bbAdmin.create.make('args', {
            start_time: parseInt(Date.now()/1000) - 3600,
            price,
            sale_type: is_instant ? "2" : "1",
          });
          let bag_id = bag.data.data.id;
          ctx.generated = {
            bag_id: bag_id,
            conch_first: true,
          };
        },
        'testSuites': [
          {
            'name': '后续验证',
            'tests': [
              async (ctx) => {
                // 验证贝壳扣减
                let info = await client.getUserInfo.make();
                utils.assert(info.data.data.info.conch + 1 === ctx.__userInfo.data.data.info.conch, "贝壳消费错误");
                return false;
              },
              async (ctx) => {
                async function oneTest(price, exp_amount) {
                  let bag = await bbAdmin.create.make('args', {
                    start_time: parseInt(Date.now()/1000),
                    price,
                  });
                  let bag_id = bag.data.data.id;
                  ctx.generated = {
                    bag_id: bag_id,
                    conch_first: true,
                  };
                  let res = await ctx.request2();
                  res = await infos.info.make('args', res.data.data.info.id);
                  let info = res.data.data.info;
                  let exp_conch_amount = price - exp_amount;
                  utils.assert(exp_amount === parseInt(info.amount) &&
                    exp_conch_amount === parseInt(info.conch_amount),
                    `贝壳支付计算错误: expected amount: ${exp_amount}, actual: ${info.amount},
                    expected conch amount: ${exp_conch_amount}, actual: ${info.conch_amount}`);
                }

                await oneTest(1, 1);
                await oneTest(100, 100);
                await oneTest(101, 1);
                await oneTest(200, 100);
                await oneTest(201, 1);
                return false;
              },
            ]
          },
        ],
      }
    ],
  },

  'pay': {
    '__proto__': base.pay,
    'api': apis.pay,
    'desc': " 支付福袋订单",
    'url': '/api/blindbox/order/pay',
    'defaults': {
      order_no: '',
      pay_type: 2,
      wx_code: '',
      wx_return_url: '',
    },
    'generated': {},
    'user': {},
    'createBagCommon': async (ctx, secne, partial = false) => {
      let res = await bbAdmin.create.make(secne);
      let bag_id = res.data.data.id;
      res = await bag.luckyBagInfo.make('id', bag_id);
      let bag_info = res.data.data.info;
      let total = parseInt(bag_info.total);
      for (let pos = 0; pos < total; pos++) {
        let rInfo = bb_utils.getRoundInfo(bag_info);
        let curPos = pos % rInfo.count_per_round;
        let curRound = rInfo.cur_round;
        res = await infos.create.make('id-pos', bag_id, curPos, false, curRound);
        ctx.generated.order_no = res.data.data.info.order_no;
        res = await ctx.request2();
        res = await client.testNotifyWX.make('args', res.data.data.info.order_no);
        // 已经卖完一轮 + 一个位置
        if (partial && curPos === 0 && curRound === 1) {
          break;
        }
        bag_info.leftover--;
      }
    },
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          await infos.create.make();
          let res = infos.create.lastResult;
          ctx.generated = {
            order_no: res.data.data.info.order_no,
          };
        }
      }, {
        'scene': 'pay-all',
        'desc': '创建一个福袋，并顺序全部购买',
        'type': GENE_ONLY,
        'func': async (ctx) => {
          await ctx.createBagCommon(ctx, 'default');
        }
      }, {
        'scene': 'pay-all-once-more',
        'desc': '创建一个福袋, once-more，并顺序全部购买',
        'type': GENE_ONLY,
        'skip': () => constants.BagType === constants.BAG_TYPE_NORMAL,
        'func': async (ctx, waitFinished = true) => {
          await ctx.createBagCommon(ctx, 'once-more');
          if (waitFinished) {
            await bb_utils.waitSuperBagToFinished(bbAdmin.create.lastResult.data.data.id);
          }
        }
      }, {
        'scene': 'pay-all-instant',
        'desc': '创建一个即买即开福袋，并顺序全部购买',
        'type': GENE_ONLY,
        'func': async (ctx) => {
          await ctx.createBagCommon(ctx, 'instant-on-sale');
        }
      }, {
        'scene': 'pay-all-get',
        'desc': '获取一个福袋，并随机全部购买，竞争购买测试',
        'type': GENE_ONLY,
        'func': async (ctx, id) => {
          let res = await bag.luckyBagInfo.make('id', id);
          id = res.data.data.info.id;
          while (true) {
            let info = res.data.data.info;
            try {
              await infos.create.geneCommon(infos.create, info);
              if (infos.create.generated.position === -1) {
                break;
              }
              res = await infos.create.request2();
              await client.testNotifyWX.make('args', res.data.data.info.order_no);
            } catch(e) {
              console.log("try-one order error", e);
              if (e.data.code === 101002) {
                // no position left, break
                break;
              }
            }
            // 再次重新获取
            res = await bag.luckyBagInfo.make('id', id);
          }
        }
      },
      {
        'scene': 'pay-all-partial',
        'desc': '创建一个超级福袋福袋，并顺序购买一轮+一个位置',
        'type': GENE_ONLY,
        'skip': () => constants.BagType !== constants.BAG_TYPE_SUPER1,
        'func': async (ctx) => {
          await ctx.createBagCommon(ctx, 'default', true);
        }
      },
    ],
  },

  'list': {
    '__proto__': base.list,
    'api': apis.list,
    'desc': "订单列表\n",
    'url': '/api/blindbox/order/list',
    'defaults': {
      page: 1,
      page_size: 10,
      type: 1,
      pay_status: -1,
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            type: constants.BagType,
          };
        }
      }, {
        'scene': 'args',
        'desc': '',
        'type': INNER,
        'func': async (ctx, page, page_size, pay_status, type) => {
          ctx.generated = {page, page_size, pay_status, type};
        }
      }, {
        'scene': 'to-pay',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            pay_status: 0,
            type: constants.BagType,
          };
        }
      }, {
        'scene': 'all-status',
        'desc': '',
        'type': GENE_ONLY,
        'func': async (ctx) => {
          for (let i = 0; i <= 4; i++) {
            await ctx.make('args', 1, 1, i);
          }
        }
      },
    ],
  },

  'info': {
    '__proto__': base.info,
    'api': apis.info,
    'desc': "订单详情，结构基本同 list 接口\n",
    'url': '/api/blindbox/order/info',
    'defaults': {
      id: 1,
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          const res = await infos.list.make();
          ctx.generated = {
            id: res.data.data.info[0].id,
          };
        }
      },
      {
        'scene': 'args',
        'desc': '',
        'type': INNER,
        'func': async (ctx, id) => {
          ctx.generated = {
            id,
          };
        }
      },
    ],
  },

  'cancel': {
    '__proto__': base.cancel,
    'api': apis.cancel,
    'desc': " 取消订单",
    'url': '/api/blindbox/order/cancel',
    'defaults': {
      order_no: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NO_BATCH,
        'func': async (ctx) => {
          let res = await infos.list.make('args', 1, 1, 0);
          ctx.generated = {
            order_no: res.data.data.info[0].order_no,
          };
        }
      },
      {
        'scene': 'create',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          let res = await infos.create.make();
          ctx.generated = {
            order_no: res.data.data.info.order_no,
          };
        }
      },
      {
        'scene': 'once-more',
        'desc': '',
        'type': NORMAL,
        'skip': () => constants.BagType === constants.BAG_TYPE_NORMAL,
        'func': async (ctx) => {
          await infos.create.make('once-more');
          ctx.generated = {
            order_no: infos.create.lastResult.data.data.info.order_no,
          };
        }
      },
      {
        'scene': 'once-more-abort-bag-first',
        'desc': '先终止福袋再取消',
        'type': NORMAL,
        'skip': () => constants.BagType === constants.BAG_TYPE_NORMAL,
        'func': async (ctx) => {
          await bbAdmin.abort.make('once-more');
          ctx.generated = {
            order_no: infos.create.lastResult.data.data.info.order_no,
          };
        }
      },
      {
        'scene': 'conch',
        'desc': '用贝壳生成订单后取消',
        'type': NORMAL,
        'func': async (ctx, is_instant = false) => {
          await infos.create.make('conch', is_instant);
          ctx.generated = {
            order_no: infos.create.lastResult.data.data.info.order_no,
          };
        },
        'testSuites': [
          {
            'name': '后续验证',
            'tests': [
              async (ctx) => {
                // 验证贝壳恢复
                let lastInfo = client.getUserInfo.lastResult;
                let info = await client.getUserInfo.make();
                utils.assert(info.data.data.info.conch === lastInfo.data.data.info.conch, "贝壳恢复错误");
                return false;
              },
            ]
          },
        ],
      },
    ],
  },

  'subBagChoose': {
    '__proto__': base.subBagChoose,
    'api': apis.subBagChoose,
    'desc': "二级福袋选择位置\n",
    'url': '/api/blindbox/order/sub-bag-choose',
    'defaults': {
      bag_id: 0,
      sub_bag_id: 0,
      positions: [],
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NO_BATCH,
        'skip': () => constants.BagType !== constants.BAG_TYPE_SUPER1,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
      {
        'scene': 'pay-all-choose-0',
        'desc': '全部支付后选择位置0',
        'type': NORMAL,
        'skip': () => constants.BagType !== constants.BAG_TYPE_SUPER1,
        'func': async (ctx) => {
          await infos.pay.make('pay-all');
          ctx.generated = {
            bag_id: bbAdmin.create.lastResult.data.data.id,
            sub_bag_id: bbAdmin.create.lastResult.data.data.sub_bags[0].id,
            positions: [0],
          };
        },
        validate: async (ctx, info) => {
          utils.assert(info.data.data.info.already_chosen.length === 0, "already_chosen 不为空");
          utils.assert(info.data.data.info.suc_count === 1, "suc_count 不为 1");

          // 再次请求
          info = await ctx.request2();
          utils.assert(info.data.data.info.already_chosen.length === 1, "already_chosen 长度不为1");
          utils.assert(info.data.data.info.suc_count === 0, "suc_count 不为 0");
        },
      },
      {
        'scene': 'pay-all-random-choose-sub-bag0',
        'desc': '全部支付后第0个二级福袋随机选择完',
        'type': NORMAL,
        'skip': () => constants.BagType !== constants.BAG_TYPE_SUPER1,
        'func': async (ctx) => {
          await infos.pay.make('pay-all');
          ctx.generated = {
            bag_id: bbAdmin.create.lastResult.data.data.id,
            sub_bag_id: bbAdmin.create.lastResult.data.data.sub_bags[0].id,
          };
        },
        async validate(ctx) {
          // this.test(ctx);
          // 再次请求
          let info;
          ctx.request2().catch((res) => {
            info = res;
          }).finally(() => {
            utils.assert([101103].indexOf(info.data.code) !== -1 , "已完成的二级福袋再次选择没有报错");
          });
        },
        test: async (ctx) => {
          // 再次请求
          let info;
          // request, using user args
          ctx.request().catch((res) => {
            info = res;
          }).finally(() => {
            utils.assert([101103].indexOf(info.data.code) !== -1 , "已完成的二级福袋再次选择没有报错");
          });
        },
      },
      {
        'scene': 'pay-all-random-choose-sub-bag-all',
        'desc': '全部支付后所有二级福袋随机选择完',
        'type': NORMAL,
        'skip': () => constants.BagType !== constants.BAG_TYPE_SUPER1,
        'func': async (ctx) => {
          await infos.pay.make('pay-all');
          ctx.generated = {
            bag_id: bbAdmin.create.lastResult.data.data.id,
          };
        },
        validate: async (ctx, info) => {
          info = await bag.luckyBagInfo.make('id', bbAdmin.create.lastResult.data.data.id);

          let bag_info = info.data.data.info;
          utils.assert(bag_info.status == constants.BAG_STATUS_FINISH, "福袋状态不是已完成");

          let b = bag_info.orders.every((item) => {
            return item.box !== null;
          });
          utils.assert(b, "不是所有的订单都关联了 box");
        },
      },
    ],
  },

  'pushChooseJob': {
    '__proto__': base.pushChooseJob,
    'api': apis.pushChooseJob,
    'desc': "加入自动选择队列，超级福地部分退款完成后调用\n",
    'url': '/api/blindbox/order/push-choose-job',
    'defaults': {
      bag_id: 0,
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NO_BATCH,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  }
};
