/*引入封装好的请求方法*/
import {dajxHttp} from '@/utils/request.js';
const post = dajxHttp.post;
const postRaw = dajxHttp.postRaw;

//定义前端接口请求名称

let url = {
    loginVerify: "/user/login",					// 用户登录接口
    telByCodeGetToken: '/api/user/loginbycode', 	// 手机验证码获取token
};

/**
 * @desc 微信登录绑定手机号
 * @param openid 前端通过[user/wx-login]微信小程序登录接口获取openid
 * @param session_key 前端通过[user/wx-login]微信小程序登录接口获取session_key
 * @param encrypted_data 前端通过getPhoneNumber获取的encryptedData
 * @param iv 前端通过getPhoneNumber获取的iv
 * @param invite_code 邀请码
 * @returns {Promise<any>}
 {
    "code": 1,
    "message": "success!",
    "__data": "i",
    "data": {
        "info": {
            "token": "67307ca2928047e78c1e3e4a49d41494"
        }
    }
}
 */
export async function wxTel(openid, session_key, encrypted_data, iv, invite_code) {
    return await post({
        url: '/api/user/wx-bind-mobile',
        data: {
            openid: openid,
            session_key: session_key,
            encrypted_data: encrypted_data,
            iv: iv,
            invite_code: invite_code,
        },
    })
}

/**
 * @desc 微信小程序登录
 * @param code 微信登录凭证,小程序调用wx.login方法获取的code值
 * @param nick_name 微信昵称,前端通过wx.getUserInfo获取的nickName值
 * @param avatar_url 微信头像,小程序调用wx.login方法获取的avatarUrl值
 * @param signature 签名
 * @param encrypted_data 数据
 * @param iv 加密
 * @returns {Promise<any>}
 {
	"code": 1,
	"message": "success!",
	"__data": "i",
	"data": {
		"info": {
		    // 登录凭证,前端根据返回的token是否为空决定是否需要绑定手机号,token为空就需要绑定手机号
			"token": "7171c1b341ff66d705db12b49947c0fc",
			// 微信用户唯一标识
			"openid": "",
			// 微信登录的session_key
			"session_key": ""
		}
	}
}
 */
export async function wxLogin(code, nick_name, avatar_url, signature, encrypted_data, iv) {
    return await post({
        url: '/api/user/wx-login',
        data: {
            code: code,
            nick_name: nick_name,
            avatar_url: avatar_url,
            signature: signature,
            encrypted_data: encrypted_data,
            iv: iv,
        },
    })
}

/**
 * @desc 轮播图数据接口
 * @param modelID array 轮播图模块ID以数组的方式
 * @param adCode string 城市代码
 * @returns {Promise<any>}
 {
  "code": 1,
  "message": "success!",
  "__data": "i",
  "data": {
    "info": [
      {
        "id": "21",
        "pid": "0",
        "title": "首页 - 顶部",
        "status": "1",
        "sort": "4",
        // 图片地址
        "img_src": "https://admin.bb.uninote.com.cn/oss?path=img/5c9641b1f19e8089c4c270a97c2e5834.jpg",
        "model_id": "1",
        // 透传参数
        "payload": {
          "type": 20,
          "id": "https://www.dajxyl.com/share/"
        },
        // 所属城市
        "ad_code": "000000",
        "city_id": "0"
      }
    ]
  }
}
 */
export async function rotationPicture(modelID, adCode) {
    return await post({
        url: '/api/banner/model',
        data: {modelID, adCode},
    })
}

/**
 * @desc 获取用户地址
 * @returns {Promise<any>}
 {
  "code": 1,
  "message": "success!",
  "__data": "i",
  "data": {
    "info": [
      {
        "id": "573",
        // 用户ID
        "uid": "10763",
        // 收货人
        "name": "name0.8797652677029066",
        // 城市代码
        "adCode": "510100",
        // 详细地址
        "desc": "环球中心W6",
        // 联系电话
        "tel": "13886347508",
        // 所属省份
        "province": "四川省",
        // 所属城市
        "city": "成都市",
        // 所属地区
        "area": "武侯区",
        // 状态(单选):0=否,1=是
        "is_default": "1",
        // 经度
        "lng": "104.060824",
        // 纬度
        "lat": "30.567234",
        // 门牌号
        "doorplate": "11",
        // 所属省份ID
        "province_city_id": "360",
        // 所属省份adcode
        "province_city_key": "510000"
      }
    ]
  }
}
 */
export async function getAddressList() {
    return await post({
        url: '/api/address/get',
        data: {}
    });
}

/**
 * @desc 设置默认用户地址
 * @param addressId 地址的ID
 * @returns {Promise<any>}
 */
export async function sefDefaultAddress(addressId) {
    return await post({
        url: '/api/address/setdefault',
        data: {
            id: addressId
        }
    });
}

/**
 * @desc 获取用户信息
 * @returns {Promise<any>}
 {
  "code": 1,
  "message": "success!",
  "__data": "i",
  "data": {
    "info": {
        // 用户id
      "uid": 10763,
      // 用户昵称
      "nickname": "",
      // 用户出生年月日
      "birthday": null,
      // 用户性别 0女，1男
      "sex": null,
      // 头像
      "pic": "https://admin.bb.uninote.com.cn/oss?path=img/default_pic.png",
      // 用户手机号码
      "phone": "18111619321",
      // 积分金币
      "integral": 0,
      // 积分金币累积的数量
      "integral_accumulated": 0,
      // 贝壳余额
      "conch": 0
    }
  }
}
 */
export async function getUserInfo() {
    return await post({
        url: '/api/user/info',
        data: {}
    });
}

/**
 * @desc 获取用户默认地址
 * @returns {Promise<any>}
 {
  "code": 1,
  "message": "success!",
  "__data": "i",
  "data": {
    "info": {
      "id": "573",
      // 用户ID
      "uid": "10763",
      // 收货人
      "name": "name0.8797652677029066",
      // 城市代码
      "adCode": "510100",
      // 详细地址
      "desc": "环球中心W611",
      // 联系电话
      "tel": "13886347508",
      // 所属省份
      "province": "四川省",
      // 所属城市
      "city": "成都市",
      // 状态(单选):0=否,1=是
      "is_default": "1",
      // 经度
      "lng": "104.060824",
      // 纬度
      "lat": "30.567234",
      // 所属省份ID
      "province_city_id": "360",
      // 所属省份adcode
      "province_city_key": "510000"
    }
  }
}
 */
export async function getDefaultAddress() {
    return await post({
        url: '/api/address/default',
        data: {}
    });
}

/**
 * @desc 获取分享邀请码
 * @param phone 电话号码
 * @returns {Promise<any>}
 {
  "code": 1,
  "message": "success!",
  "__data": "i",
  "data": {
    "info": {
      // 邀请码
      "code": "1J6FHV"
    }
  }
}
 */
export async function codeInvitation(phone) {
    return await post({
        url: '/api/user/invite',
        data: {
            phone: phone
        }
    });
}

/**
 * @desc 微信付款
 * @param orderID 订单编号(不是订单ID,是订单编号)
 * @param type 支付方式 （默认）1：微信APP 支付 2：微信小程序支付 3：微信H5支付 4:微信公众号支付
 * @param wx_code 微信网页授权code(支付方式为微信公众号支付必传,其他支付方式可以不传)
 * @param wx_return_url 微信H5支付回调地址(本参数仅在支付方式为微信H5支付时有效,其他支付方式可以不传)
 * @returns {Promise<any>}
 {
  "code": 1,
  "message": "success!",
  "data": {
    "__info": "i",
    "info": {
      "code": 1,
      "error": "",
      "info": {
        "appid": "wx1288370bcddf64a6",
        "partnerid": "1561352391",
        "prepayid": "wx06133735377212029ee10fa58dbe2f0000",
        "timestamp": "1630906655",
        "noncestr": "agi9MHXoIltR8OBj",
        "package": "Sign=WXPay",
        "sign": "A4CF95771AD8C7D9CCF80514868415D6"
      },
      "status": true
    }
  }
}
 */
export async function wxpay(orderID, type = 2, wx_code = '', wx_return_url = '') {
    return await post({
        url: '/api/pay/wx',
        data: {orderID, type, wx_code,wx_return_url}
    });
}

/**
 * @desc 添加新服务地址接口
 * @param name
 * @param adCode
 * @param desc
 * @param doorplate 门牌号信息
 * @param tel
 * @param province
 * @param city
 * @param area
 * @param lng
 * @param lat
 * @returns {Promise<any>}
 {
  "code": 1,
  "message": "success!",
  "__data": "i",
  "data": {
    // 地址ID
    "info": "585"
  }
}
 */
export async function addAddress(name, adCode, desc, doorplate, tel, province, city, area, lng, lat, is_default) {
    return await post({
        url: '/api/address/set',
        data: {
            name: name,
            adCode: adCode,
            desc: desc,
            doorplate: doorplate,
            tel: tel,
            province: province,
            city: city,
            area: area,
            lng: lng,
            lat: lat,
            is_default,
        }
    });
}

/**
 * @desc 设置页面提交用户修改完成的数据
 * @name /api/user/updateinfo
 * @method POST
 * @param {Object} params
 */
/**
 * @desc 设置页面提交用户修改完成的数据
 * @param birthday
 * @param nickname
 * @param phone
 * @param sex
 * @param pic
 * @returns {Promise<any>}
 */
export async function updateUserData(birthday,nickname,phone,sex,pic) {
    let params = {info:{}};
    if (sex) {
        params.info.sex = parseInt(sex);
    }
    if (birthday) {
        params.info.birthday = birthday;
    }
    if (nickname) {
        params.info.nickname = nickname;
    }
    if (phone) {
        params.info.phone = phone;
    }
    if (pic) {
        params.info.pic = pic;
    }
    return await post({
        url: '/api/user/updateinfo',
        data: params
    });
}

/**
 * 图片上传（支持多图批量上传）
 * @param file_base64 图片文件base64值
 * @param scene 场景 (默认空, 不验证图片大小及尺寸,根据不同场景对图片的尺寸和大小限制不一样)
  可选值：
  shop_admin_product_image_1：商城管理后台上传商品入户图场景
  shop_admin_product_image_2：商城管理后台上传商品banner图场景
  shop_admin_product_category_image_1：商城管理后台上传商品分类图场景
  shop_admin_promotion_category_image_1：商城管理后台上传促销活动分类图片场景
 * @returns {Promise<*>}
 {
  "code": 1,
  "message": "success!",
  "data": {
    "info": [
      {
        // 图片可访问地址
        "url": "https://admin.bb.uninote.com.cn/oss?path=uploads/images/2021/09/647c3f605c39d3dc967ed6768cab9717.png",
        // 图片KEY值
        "file_key": "647c3f605c39d3dc967ed6768cab9717"
      }
    ]
  }
}
 */
export async function updateUserImg(file_base64, scene) {
    return await post({
        url: '/api/upload/image',
        data: {file_base64, scene}
    });
}

/**
 * @desc 图片上传，通过 url
 *
 * @param url 图片 url
 * @returns {Promise<*>} {
  "code": 1,
  "message": "success!",
  "data": {
    "info": {
      "url": "https://admin.bb.uninote.com.cn/oss?path=uploads/images/2023/04/469f5fc80587808937e2d6e2e34a4da4.jpg"
    }
  }
}
 */
export async function uploadUrlImg(url) {
  return await post({
      url: '/api/upload/url-image',
      data: {url}
  });
}

/**
 * @desc 用户删除地址
 * @param id 地址ID
 * @returns {Promise<any>}
 */
export async function deleteAddress(id) {
    return await post({
        url: '/api/address/del',
        data: {
            id: id
        }
    });
}

/**
 * @desc 修改用户地址
 * @param id 地址ID
 * @param name 名称
 * @param adCode 城市ID
 * @param desc 详情描述
 * @param doorplate 门牌号信息
 * @param tel 电话号码
 * @param province 省
 * @param city 城市
 * @param area 区
 * @param lng 经度
 * @param lat 纬度
 * @param is_default 是否默认 0 否 1 是
 * @returns {Promise<any>}
 */
export async function editAddress(id, name, adCode, desc, doorplate, tel, province, city, area, lng, lat, is_default) {
    return await post({
        url: '/api/address/edit',
        data: {
            id: id,
            name: name,
            adCode: adCode,
            desc: desc,
            doorplate: doorplate,
            tel: tel,
            province: province,
            city: city,
            area: area,
            lng: lng,
            lat: lat,
            is_default: is_default,
        }
    });
}

/**
 * @desc 不知道哪冒出来的方法接口 @fixme
 * @param parma
 * @returns {Promise<any>}
 */
export async function loginVerify(parma) {
    return await dajxHttp.get({
        url: '/api/user/login',
        data: parma,
    })
}

/**
 * @desc 发送验证码
 * @param phone
 * @param scenariors 场景
 *  register 注册场景
 *  login 验证码登录场景
 *  forget 忘记密码场景
 *  beadhouse 康养中心托管申请场景
 *  beadhouseMan 康养中心申请入住场景
 *  volunteer 志愿者申请场景
 *  rescue 慈善捐助
 *  verify_tel 验证原有手机
 *  update_tel 修改手机号
 *  update_pass 修改密码
 * @returns {Promise<any>}
 */
export async function telForCode(phone, scenariors) {
    return await post({
        url: '/api/code/get',
        data: {
            phone: phone,
            scenariors: scenariors
        }
    })
}

/**
 * @desc 用户登录 - 验证码登录
 * @param phone 手机号
 * @param verifyCode 短信验证码
 * @param invite_code 邀请码
 * @param cid 个推设备唯一ID
 * @param cityCode 城市code 默认0：全国
 * @returns {Promise<any>} {
  "code": 1,
  "message": "success!",
  "data": {
    "token": "a7b18a0ac3bf80cae9ed9babeb8cab97",
    "__id": "t",
    "id": 11693
  }
}
 */
export async function telByCodeGetToken(phone,verifyCode,invite_code = null,cid = null,cityCode = null) {
    let params = {};
    if (invite_code) {
        params.invite_code = invite_code;
    }
    if (cid) {
        params.cid = cid;
    }
    if (cityCode) {
        params.cityCode = cityCode;
    }
    params.phone = phone;
    params.verifyCode = verifyCode;

    return await post({
        url: '/api/user/loginbycode',
        data: params
    });
}

/**
 * @desc 微信支付回调（仅用于测试）
 * @param {Object} orderID
 * @param wx_no 微信订单号
 */
export function testNotifyWX(orderID, wx_no) {
    let data = `<xml>
  <appid><![CDATA[wx2421b1c4370ec43b]]></appid>
  <attach><![CDATA[支付测试]]></attach>
  <bank_type><![CDATA[CFT]]></bank_type>
  <fee_type><![CDATA[CNY]]></fee_type>
  <is_subscribe><![CDATA[Y]]></is_subscribe>
  <mch_id><![CDATA[10000100]]></mch_id>
  <nonce_str><![CDATA[5d2b6c2a8db53831f7eda20af46e531c]]></nonce_str>
  <openid><![CDATA[oUpF8uMEb4qRXf22hE3X68TekukE]]></openid>
  <out_trade_no><![CDATA[${orderID}]]></out_trade_no>
  <result_code><![CDATA[SUCCESS]]></result_code>
  <return_code><![CDATA[SUCCESS]]></return_code>
  <sign><![CDATA[B552ED6B279343CB493C5DD0D78AB241]]></sign>
  <sub_mch_id><![CDATA[10000100]]></sub_mch_id>
  <time_end><![CDATA[20140903131540]]></time_end>
  <total_fee>1</total_fee><coupon_fee><![CDATA[10]]></coupon_fee>
<coupon_count><![CDATA[1]]></coupon_count>
<coupon_type><![CDATA[CASH]]></coupon_type>
<coupon_id><![CDATA[10000]]></coupon_id>
<coupon_fee_0><![CDATA[100]]></coupon_fee_0>
  <trade_type><![CDATA[JSAPI]]></trade_type>
  <transaction_id><![CDATA[${wx_no}]]></transaction_id>
</xml>`;
    return postRaw({
      url: '/api/notify/wx',
      data,
      header: {'content-type': "application/xml"},
    });
}

/**
 * @desc 获取 App 配置
 * @returns {Promise<*>}
 {
  "code": 1,
  "message": "success!",
  "__data": "i",
  "data": {
    "info": {
      // 服务热线
      "service_tel": "‭400 036 0036‬",
      // 用户服务协议
      "User_service_agreement": "https://admin.bb.uninote.com.cn/privacy/index.html"
    },
    "status": true
  }
}
 */
export async function home_config() {
    return await post({
        url: '/api/home/config',
        data: {}
    });
}

/**
 * @desc 获取邀请人数页面聚合数据
 * @param phone 手机号码
 * @returns {Promise<*>}
 {
	"code": 1,
	"message": "success!",
	"__data": "i",
	"data": {
		"info": {
		    // 邀请人数
			"invited_num": 2,
			"__invited_parent": "i",
			"invited_parent": {
			    // 邀请人姓名
				"nickname": null,
				// 邀请人电话
				"phone": "13575386590"
			},
			"__invited_child": "i",
			"invited_child": [
				{
				    // 姓名
					"nickname": null,
					// 电话
					"phone": "18898529172",
					// 创建时间
					"create_time": "2020-08-27 14:44:42"
				}
			]
		}
	}
}
 */
export async function user_invite_group_info(phone) {
    return await post({
        url: '/api/user/invite-group-info',
        data: {phone}
    });
}

/**
 * @desc 添加我的邀请者
 * @param phone 手机号码
 * @param invite_code 邀请码
 * @returns {Promise<*>}
 */
export async function user_add_inviter(phone,invite_code) {
    return await post({
        url: '/api/user/add-inviter',
        data: {phone,invite_code}
    });
}

/**
 * @desc 获取我邀请的好友列表
 * @param phone 手机号码
 * @returns {Promise<*>}
 {
	"code": 1,
	"message": "success!",
	"__data": "i",
	"data": {
		"info": {
			"__invited_child": "k",
			"invited_child": [
				{
					 // 姓名
					"nickname": null,
					// 电话
					"phone": "18898529172",
					// 创建时间
					"create_time": "2020-08-27 14:44:42"
				}
			]
		}
	}
}
 */
export async function user_get_me_invited(phone) {
    return await post({
        url: '/api/user/get-me-invited',
        data: {phone}
    });
}

/**
 * @desc 点击邀请好友聚合接口
 * @param phone 手机号码
 * @returns {Promise<*>}
 {
  "code": 1,
  "message": "success!",
  "__data": "i",
  "data": {
    "info": {
       // 链接
      "inviter_url": "https://api.dajxyl.com/share/?invite_code=1MZKZJ",
       // 邀请码
      "inviter_code": "1MZKZJ"
    }
  }
}
 */
export async function user_invite_friend(phone) {
    return await post({
        url: '/api/user/invite-friend',
        data: {phone}
    });
}

/**
 * @desc 获取邀请码
 * @param phone 手机号码
 * @returns {Promise<*>}
 {
  "code": 1,
  "message": "success!",
  "__data": "i",
  "data": {
    "info": {
      // 邀请码
      "code": "1MZKZJ"
    }
  }
}
 */
export async function user_invite(phone) {
    return await post({
        url: '/api/user/invite',
        data: {phone}
    });
}

/**
 * @desc 注销账户
 * @returns {Promise<*>}
 {
  "code": 1,
  "message": "success!",
  "data": {}
}
 */
export async function userCloseAccount() {
    return await post({
        url: '/api/user/close-account',
        data: {}
    });
}

/**
 * @desc 获取微信公众号网页授权
 * @param wx_return_url 微信H5支付回调地址(本参数仅在支付方式为微信H5支付时有效,其他支付方式可以不传)
 * @returns {Promise<*>}
 {
  "code": 1,
  "message": "success!",
  "data": {
    "info": {
      // 前端跳转此URL后可得到微信公众号网页授权code
      "url": "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe5f7e1347984d353&redirect_uri=http%3A%2F%2Flocalhost%2F%3Fphone%3D18111619307%26staff_id%3D5%26phone_staff%3D13668213798%26passwd_staff%3D123456%26api%3Dclient%252Findex%26select%3DgetWxCode&response_type=code&scope=snsapi_base&state=wechat_redirect#wechat_redirect"
    }
  }
}
 */
export async function getWxCode(wx_return_url) {
  return await post({
    url: '/api/user/get-wx-code',
    data: {wx_return_url}
  });
}

/**
 * @desc 获取微信 JS-SDK 配置信息
 *
 * @returns {Promise<*>} {
  "code": 1,
  "message": "success!",
  "data": {
    "info": {
      "timeStamp": "1637493229",
      "nonceStr": "MlFc3V4HKh2CIT8Z",
      "url": "http://a.dajx.my:8081/?phone=18982283449&staff_id=13&phone_staff=13320817290&passwd_staff=123456&cmsphpsid=i0kflsl3jemc8vgm8bm0fo6qtj&debug=1&select-module=client%2Findex&select-api=getWxJsapiConfig",
      "$signature": "9673d4f69ee833dc218f2b44eaa7299e3b18526a"
    }
  }
}
 */
export async function getWxJsapiConfig() {
  return await post({
    url: '/api/user/get-wx-jsapi-config',
    data: {}
  });
}

/**
 * @desc user visit report
 *
 * @param visit_id 存储于客户端的访问 unique id，如果不传，则由服务器生成
 * @param source_id 渠道 id
 * @returns {Promise<*>} {
  "code": 1,
  "message": "success!",
  "data": {
    "info": {
      "visit_id": "61a8602dd0991"
    }
  }
}
 */
export async function visit(visit_id, source_id) {
  return await post({
    url: '/api/user/visit',
    data: {visit_id, source_id}
  });
}
